import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, Paper, Stack } from '@mui/material';
import { addDays } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { schema, SchemaType } from '../../pages/CheckerPage/schema';
import { FooterRow, InputRows, RowHeaders } from '../InputRow';

import { DataValidInfo } from './DataValidInfo';

const MAX_INPUTS_LENGTH = 10;

function getInitialState(maxDate: Date = new Date()) {
  return {
    inputs: [
      {
        location: undefined,
        dates: [maxDate, maxDate],
      },
    ],
  };
}

interface IProps {
  isDisabled?: boolean;
  maxDate?: Date;
  onSubmit: (value: SchemaType) => void;
}

export function InputForm({ isDisabled, maxDate, onSubmit }: IProps) {
  const { control, handleSubmit, reset, watch } = useForm<SchemaType>({
    defaultValues: { ...getInitialState(maxDate) },
    resolver: zodResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inputs',
  });

  // Reset the form when the maxDate comes in
  useEffect(() => {
    if (maxDate) {
      reset({ ...getInitialState(maxDate) });
    }
  }, [maxDate, reset]);

  // Computed values
  const inputs = watch('inputs');
  const hasMaxInputs = fields.length >= MAX_INPUTS_LENGTH;

  // Event handlers
  const onAddRow = useCallback(() => {
    const lastField = inputs.at(-1);

    if (lastField && maxDate) {
      const lastFieldDates = lastField.dates;
      const dayAfterLast = addDays(lastFieldDates[1], 1);
      const isBeforeMaxDate = dayAfterLast < maxDate;

      const newDate = isBeforeMaxDate ? dayAfterLast : maxDate;

      append({
        // force a undefined input since it will be blank until user fills it in
        location:
          undefined as unknown as SchemaType['inputs'][number]['location'],
        dates: [newDate, newDate],
      });
    }
  }, [append, inputs, maxDate]);

  const onRemoveRow = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const disabled = isDisabled || !maxDate;

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSubmit, (data) => console.log(data))}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '800px',
        overflow: 'hidden',
      }}
      elevation={0}
    >
      <Grid container spacing={0} height="100%" flexGrow={1}>
        {/* Left side */}
        <Grid item xs={12} padding={4} pr={0}>
          <Grid container spacing={2}>
            <DataValidInfo maxDate={maxDate} />
            <RowHeaders />
            <InputRows
              control={control}
              disabled={disabled}
              fields={fields}
              maxDate={maxDate}
              onRemoveRow={fields.length > 1 ? onRemoveRow : undefined}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} px={4} pb={4}>
          <Stack direction="row">
            <FooterRow
              disabled={disabled || hasMaxInputs}
              onAddRow={onAddRow}
            />
            <Button
              color="secondary"
              disabled={disabled}
              size="large"
              sx={{ mt: 1, minWidth: 200 }}
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
