import { Button, Stack, Typography } from '@mui/material';
import { CSVLink } from 'react-csv';

import { InfoBox } from '../InfoBox/InfoBox';
import { Download } from '../SVGs';

interface IProps {
  isLoading?: boolean;
  cumulativeRainfall?: number;
  data?: any;
}

export function ResultHeader({
  data = [],
  isLoading,
  cumulativeRainfall,
}: IProps) {
  // add thousand grouping and fixed 2
  const formattedRainfall = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    unit: 'millimeter',
    style: 'unit',
  }).format(cumulativeRainfall || 0);

  return (
    <Stack direction="row" justifyContent="space-between" mb={3}>
      <Stack direction="row">
        <Typography color="secondary.main" fontSize="2rem" mb={1} variant="h2">
          Results
        </Typography>
        <CSVLink
          data={data}
          headers={[
            { label: 'City', key: 'city' },
            { label: 'Country', key: 'country' },
            { label: 'Location', key: 'latLng' },
            { label: 'Date', key: 'date' },
            { label: 'Rainfall (mm)', key: 'rainfall' },
          ]}
          filename={`rainfall-${new Date().toISOString()}.csv`}
        >
          <Button>
            <Download height={24} width={24} />
          </Button>
        </CSVLink>
      </Stack>
      <InfoBox
        isLoading={isLoading}
        title="Cumulative Rainfall"
        value={formattedRainfall}
      />
    </Stack>
  );
}
