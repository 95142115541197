import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config/config';

import { Metadata } from './interfaces';

const ORIGIN_URL = config.api.grapqlEndpoint.replace('/graphql', '');
const REST_URL = ORIGIN_URL + '/rest/metadata/';
// const ERA5_METADATA_PATH = 'era5-precip-hourly';
const CHIRP_METADATA_PATH = 'chirps_final_05-daily';

export const dclimateApi = createApi({
  reducerPath: 'dclimateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REST_URL,
  }),
  endpoints: (builder) => ({
    metaCHIRPPrecipHourly: builder.query<Metadata, undefined>({
      query: () => CHIRP_METADATA_PATH,
    }),
  }),
});

export const {
  useLazyMetaCHIRPPrecipHourlyQuery,
  useMetaCHIRPPrecipHourlyQuery,
} = dclimateApi;
