import { Components } from '@mui/material';

import { backgroundImage } from '../../helpers/publicAssetLinks';

import { baseTheme } from './base';

const borderRadius = '8px';

const popperPaperBaseStlye = {
  borderRadius: '0',
  '::before': {
    content: '""',
    backgroundColor: baseTheme.palette.grey[30],
    height: '1px',
    left: '20px',
    position: 'absolute',
    right: '20px',
  },
};

const popperPaperDirectionBottomStyles = {
  ...popperPaperBaseStlye,
  borderTop: 'none',
  borderBottomLeftRadius: borderRadius,
  borderBottomRightRadius: borderRadius,
  top: '-6px',
  '::before': {
    ...popperPaperBaseStlye['::before'],
    top: '1px',
  },
};

const popperPaperDirectionTopStyles = {
  ...popperPaperBaseStlye,
  borderBottom: 'none',
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  top: '6px',
  '::before': {
    ...popperPaperBaseStlye['::before'],
    bottom: '1px',
  },
};

export const components: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        boxShadow: 'none',
        borderColor: baseTheme.palette.success.main,
        position: 'relative',
      },
      inputRoot: {
        '&.Mui-focused': {
          borderRadius: borderRadius,
          borderColor: baseTheme.palette.grey[50],
        },
      },
      popper: {
        "&[data-popper-placement='bottom']": {
          '.MuiAutocomplete-paper': {
            ...popperPaperDirectionBottomStyles,
          },
        },
        "&[data-popper-placement='top']": {
          '& .MuiAutocomplete-paper': {
            ...popperPaperDirectionTopStyles,
          },
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
        overflow: 'auto',
      },
      body: {
        backgroundColor: '#1a1a1a',
        minHeight: '100%',
        overflow: 'auto',
      },
      '#root': {
        minHeight: '100vh',
        paddingBottom: '118px',
        position: 'relative',
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(18, 22, 42, 0.85)',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        boxShadow: 'none',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        'label[data-shrink=false] + .MuiInputBase-formControl &::placeholder': {
          // mui base has 'opacity: 0 !important by default
          // need !important to override
          opacity: '1 !important',
        },
        '::placeholder': {
          color: baseTheme.palette.grey[400],
          opacity: 1,
        },
      },
      root: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      input: {
        'label[data-shrink=false] + .MuiInputBase-formControl &::placeholder': {
          color: baseTheme.palette.grey[400],
          // mui base has 'opacity: 0 !important by default
          // need !important to override
          opacity: '1 !important',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      sizeSmall: {
        input: {
          padding: '8.5px 14px',
        },
      },
      root: {
        // boxShadow: baseTheme.shadows[2],
        backgroundColor: '#fff',
        borderRadius: '8px',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: baseTheme.palette.grey[50],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: baseTheme.palette.success.main,
          borderWidth: '1px',
        },
      },
      multiline: {
        padding: '0',
      },
      inputAdornedStart: {
        paddingLeft: '0',
      },
      inputAdornedEnd: {
        paddingRight: '0',
      },
      input: {
        'label[data-shrink=false] + .MuiInputBase-formControl &::placeholder': {
          // mui base has 'opacity: 0 !important by default
          // need !important to override
          opacity: '1 !important',
        },
        '::placeholder': {
          color: baseTheme.palette.grey[400],
          opacity: 1,
        },
      },
      notchedOutline: {
        borderColor: baseTheme.palette.grey[300],
        legend: {
          display: 'none',
        },
        top: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      filled: {
        color: baseTheme.palette.common.black,
        left: '12px',
      },
      outlined: {
        marginBottom: '8px',
        position: 'static',
      },
      root: {
        transform: 'none',
      },
      standard: {
        position: 'static',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      outlined: {
        borderWidth: '2px',
        ':hover': {
          borderWidth: '2px',
        },
      },
      contained: {
        color: baseTheme.palette.common.white,
        ':disabled': {
          color: baseTheme.palette.common.white,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: baseTheme.palette.grey[500],
        borderRadius: '16px',
      },
    },
  },
};
