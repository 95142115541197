import { Fragment, useCallback, useState } from 'react';

import { ErrorModal } from '../../components/Modals';
import { GetRainfallDataByLocationsQueryVariables } from '../../graphql/generated';
import { useTransformDataIntoRowData } from '../../hooks';
import { useGetRainfallDataByLocationsEndpointMutation } from '../../store/api';
import { IResource } from '../../store/bingMapApi/interfaces';

import { CheckerPage } from './CheckerPage';
import { InputType, SchemaType } from './schema';

export const QUOTING_BUFFER_DAYS = 10;

function mapValuesToRequest(values: InputType) {
  const location = values.location as IResource;
  const coordinates = location.point.coordinates;

  const city = location.address.locality || '';
  const country = location.address.countryRegion;

  return {
    city,
    country,
    endDate: values.dates[1].toISOString(),
    location: coordinates,
    startDate: values.dates[0].toISOString(),
  };
}

export function CheckerPageContainer() {
  const [mutate, { data: response, isLoading, error, reset: resetMutation }] =
    useGetRainfallDataByLocationsEndpointMutation();

  const [submittedValues, setSubmittedValues] = useState<
    SchemaType | undefined
  >(undefined);
  const [customError, setCustomError] = useState<string | undefined>();

  const {
    isPrepping,
    tableData,
    reset: resetPreppedData,
    summary,
  } = useTransformDataIntoRowData(response?.getCHIRPRainfallDataByLocations);

  const onSubmitHandler = useCallback(
    (values: SchemaType) => {
      const inputs: GetRainfallDataByLocationsQueryVariables['input'] =
        values.inputs
          .sort((a, b) => a.dates[1].getTime() - b.dates[1].getTime())
          .map(mapValuesToRequest);

      resetPreppedData();
      setSubmittedValues(values);
      mutate({ input: inputs }).then((results) => {
        if (
          'data' in results &&
          !results?.data?.getCHIRPRainfallDataByLocations.length
        ) {
          setCustomError('No data found');
        }
      });
    },
    [mutate, resetPreppedData],
  );

  const resetHandler = useCallback(() => {
    resetMutation();
    setSubmittedValues(undefined);
    setCustomError(undefined);
  }, [resetMutation]);

  return (
    <Fragment>
      <CheckerPage
        data={tableData}
        isLoading={isLoading || isPrepping}
        onSubmit={onSubmitHandler}
        summary={summary}
      />
      <ErrorModal
        open={Boolean(error) || Boolean(customError)}
        onClose={resetHandler}
      >
        {customError}
      </ErrorModal>
    </Fragment>
  );
}
