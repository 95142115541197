/* eslint-disable @typescript-eslint/no-loss-of-precision */
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { formatLatLng } from '../../helpers/format';
import { useLazyQueryBingGeocoderQuery } from '../../store/bingMapApi';
import { IResource } from '../../store/bingMapApi/interfaces';

interface IProps<T extends FieldValues> {
  control: Control<T>;
  disabled?: boolean;
  name: Path<T>;
  placeholder?: string;
}

export function LocationAutocomplete<T extends FieldValues>({
  control,
  disabled,
  name,
  placeholder,
}: IProps<T>) {
  const [lazyQuery, { isLoading }] = useLazyQueryBingGeocoderQuery();
  const [value, setValue] = useState<IResource | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<IResource[]>([]);

  const lazyQueryThrottled = useMemo(
    () =>
      throttle((query: string) => lazyQuery(query, true), 50, {
        trailing: false,
        leading: true,
      }),
    [lazyQuery],
  );

  useEffect(() => {
    if (inputValue.length < 4) {
      return;
    }

    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    lazyQueryThrottled(inputValue)?.then((results) => {
      if (active) {
        let newOptions: IResource[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results?.data?.resourceSets?.[0]?.resources) {
          const fetchedOptions = results?.data?.resourceSets?.[0]?.resources;

          newOptions = [...newOptions, ...(fetchedOptions || [])];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, lazyQueryThrottled, value]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          id="bing-geocoder-service"
          data-testid="bing-geocoder-service"
          disabled={disabled}
          autoComplete
          filterOptions={(x) => x}
          filterSelectedOptions
          getOptionLabel={(option) => option.name}
          includeInputInList
          loading={isLoading}
          options={options}
          sx={{ width: '100%' }}
          value={value}
          onChange={(event: any, newValue: IResource | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            field.onChange(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
                startAdornment: <SearchOutlined sx={{ color: 'grey.50' }} />,
              }}
              error={Boolean(error)}
              helperText={error?.message}
              placeholder={placeholder}
              fullWidth
            />
          )}
          renderOption={(props, option) => {
            // Highlight text
            const matches = match(option.name, inputValue);
            const parts = parse(option.name, matches);
            const latLngFormat = formatLatLng(option.point.coordinates);

            // eslint-disable-next-line react/prop-types
            const key = `${props.id}-${option.name}`;

            return (
              <li {...props} key={key}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 500,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography
                      color="grey.80"
                      fontWeight={400}
                      fontSize="0.875rem"
                    >
                      {latLngFormat}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
}
