import { format } from 'date-fns';

import { IRowData, ISummary } from '../components/Results/interfaces';
import { GetRainfallDataByLocationsQuery } from '../graphql/generated';

import { formatLatLng } from './format';

function formatHypenedDate(dateString: string) {
  const reformatedDateString = dateString.replace(/-/g, '/');

  return format(new Date(reformatedDateString), 'M/d/yyyy');
}

export function transformResponseTableRows(
  data: GetRainfallDataByLocationsQuery['getCHIRPRainfallDataByLocations'],
): IRowData[] {
  if (!data) {
    return [];
  }

  return data.map(
    (item): IRowData => ({
      city: item.city,
      coordinates: item.location as [number, number],
      country: item.country,
      date: formatHypenedDate(item.date),
      latLng: formatLatLng(item.location as [number, number]),
      rainfall: item.rainfall,
    }),
  );
}

export function aggergateRowsIntoSummary(rows: IRowData[]): ISummary {
  return {
    cumulativeRainfall: rows.reduce((acc, row) => acc + row.rainfall, 0),
  };
}
