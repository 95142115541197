import { Box, Button } from '@mui/material';

import { useAuth } from '../../hooks';
import { EldersLogo } from '../SVGs';

const headerHeight = '60px';

export function Header() {
  const { logout, isLoggedIn } = useAuth();

  return (
    <Box
      bgcolor="secondary.main"
      sx={{
        height: headerHeight,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          justifyContent: 'space-between',
          margin: '0 auto',
          maxWidth: '1112px',
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            color: 'white',
          }}
        >
          <EldersLogo width="76" height="22" />
        </Box>
        {isLoggedIn && (
          <Box>
            <Button onClick={logout} sx={{ color: 'white' }} variant="text">
              Logout
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
