/* eslint-disable @typescript-eslint/no-explicit-any, import/no-named-as-default */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  EmailAddress: any;
  JSON: any;
  PhoneNumber: any;
  Upload: any;
};

export type AcceptClaimsFormTokenInviteResponse = {
  __typename?: 'AcceptClaimsFormTokenInviteResponse';
  auth?: Maybe<AuthUserPayload>;
  contract?: Maybe<Contract>;
};

export type AcceptClientReviewContractInviteResponse = {
  __typename?: 'AcceptClientReviewContractInviteResponse';
  auth?: Maybe<AuthUserPayload>;
  contract?: Maybe<Contract>;
};

export enum AccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export type AddPropertyInputs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AddressInputs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AgentSignatureInput = {
  date: Scalars['DateTime'];
  signature: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type AgentStatsPayload = {
  __typename?: 'AgentStatsPayload';
  totalActivePolicies: Scalars['Int'];
  totalContractsExpiringSoon: Scalars['Int'];
  totalPremiumIndications: Scalars['Int'];
};

export type AggregatedPayoutReportInputs = {
  arbolId?: InputMaybe<Scalars['String']>;
  counterparty?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  payout?: InputMaybe<Scalars['Float']>;
  portfolio?: InputMaybe<
    Array<InputMaybe<AggregatedPayoutReportPortfolioInputs>>
  >;
  premium?: InputMaybe<Scalars['Float']>;
};

export type AggregatedPayoutReportPortfolioInputs = {
  estimatedEp?: InputMaybe<Scalars['Float']>;
  estimatedStDev?: InputMaybe<Scalars['Float']>;
  finalIndex?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  payoutZscore?: InputMaybe<Scalars['Float']>;
};

export type Agreements = {
  __typename?: 'Agreements';
  agreeClaimsClient?: Maybe<Scalars['Boolean']>;
  agreeElectronicBroker?: Maybe<Scalars['Boolean']>;
  agreeElectronicClient?: Maybe<Scalars['Boolean']>;
  agreePrivacyPolicy?: Maybe<Scalars['Boolean']>;
  agreeTerrorismDisclosure?: Maybe<Scalars['Boolean']>;
  signNameBroker?: Maybe<Scalars['String']>;
  signNameCustomer?: Maybe<Scalars['String']>;
};

export type AnnualRevenue = {
  __typename?: 'AnnualRevenue';
  annualRevenueCurrency?: Maybe<Scalars['String']>;
  revenueYears?: Maybe<Array<Maybe<YearRevenue>>>;
};

export enum AppEncryptKeyType {
  Insurance = 'INSURANCE',
  Legacy = 'LEGACY',
}

export enum AppType {
  Cm = 'CM',
  Default = 'DEFAULT',
  Dre = 'DRE',
  Hh = 'HH',
  Pm = 'PM',
  Rm = 'RM',
  Singlife = 'SINGLIFE',
}

export type Application = {
  __typename?: 'Application';
  agentInformation?: Maybe<ContractUserInformation>;
  agreements?: Maybe<Agreements>;
  approvalsObj?: Maybe<ApprovalsObj>;
  clientInformation?: Maybe<ClientUserInformation>;
  productType?: Maybe<ProductType>;
  reviewDetails?: Maybe<ApplicationReviewDetails>;
  signatureDetails?: Maybe<ApplicationSignatureDetails>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ApplicationStatus>;
  type?: Maybe<Scalars['String']>;
};

export type ApplicationReviewDetails = {
  __typename?: 'ApplicationReviewDetails';
  isApproved?: Maybe<Scalars['Boolean']>;
  reviewBy?: Maybe<User>;
  reviewDate?: Maybe<Scalars['String']>;
  reviewDescription?: Maybe<Scalars['String']>;
};

export type ApplicationSignatureDetails = {
  __typename?: 'ApplicationSignatureDetails';
  brokerSignature?: Maybe<Scalars['String']>;
  brokerSigningDate?: Maybe<Scalars['DateTime']>;
  clientSignature?: Maybe<Scalars['String']>;
  clientSigningDate?: Maybe<Scalars['DateTime']>;
  d1Signature?: Maybe<Scalars['String']>;
  d1SigningDate?: Maybe<Scalars['DateTime']>;
};

export enum ApplicationStatus {
  Approved = 'APPROVED',
  AwaitingAgent = 'AWAITING_AGENT',
  AwaitingClient = 'AWAITING_CLIENT',
  Cancelled = 'CANCELLED',
  ClientReviewed = 'CLIENT_REVIEWED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export enum ApplicationType {
  Energy = 'ENERGY',
  Insurance = 'INSURANCE',
  Parametric = 'Parametric',
}

export type ApprovalsObj = {
  __typename?: 'ApprovalsObj';
  businessType?: Maybe<Scalars['Boolean']>;
  closingDate?: Maybe<Scalars['Boolean']>;
  contractLimit?: Maybe<Scalars['Boolean']>;
  programAggregateLimit?: Maybe<Scalars['Boolean']>;
  stateAndCountyMatch?: Maybe<Scalars['Boolean']>;
};

export type ApproveLegacyContractInputs = {
  changedBy?: InputMaybe<Scalars['String']>;
  contractId: Scalars['String'];
  teamType: TeamType;
};

export type ApproveLegacyTransactionInputs = {
  approvedBy?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
};

export type AuthUserPayload = {
  __typename?: 'AuthUserPayload';
  token: Scalars['String'];
  user: User;
};

export type AuthorizeAgencyOnboardingUserResponse = {
  __typename?: 'AuthorizeAgencyOnboardingUserResponse';
  auth?: Maybe<AuthUserPayload>;
};

export type AuthorizedSignatoryInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BackgroundDetails = {
  __typename?: 'BackgroundDetails';
  bankruptcy?: Maybe<Scalars['Boolean']>;
  bankruptcyExplanation?: Maybe<Scalars['String']>;
  carryFidelityBond?: Maybe<Scalars['Boolean']>;
  dishonesty?: Maybe<Scalars['Boolean']>;
  dishonestyExplanation?: Maybe<Scalars['String']>;
  felonyConviction?: Maybe<Scalars['Boolean']>;
  felonyExplanation?: Maybe<Scalars['String']>;
  lawsuits?: Maybe<Scalars['Boolean']>;
  lawsuitsExplanation?: Maybe<Scalars['String']>;
  licenseRevoked?: Maybe<Scalars['Boolean']>;
  licenseRevokedExplanation?: Maybe<Scalars['String']>;
  maintainedInTrustAccount?: Maybe<Scalars['Boolean']>;
  premiumVolumes?: Maybe<Array<Maybe<OrgPremiumVolume>>>;
};

export type BackgroundDetailsInputs = {
  bankruptcy?: InputMaybe<Scalars['Boolean']>;
  bankruptcyExplanation?: InputMaybe<Scalars['String']>;
  carryFidelityBond?: InputMaybe<Scalars['Boolean']>;
  dishonesty?: InputMaybe<Scalars['Boolean']>;
  dishonestyExplanation?: InputMaybe<Scalars['String']>;
  felonyConviction?: InputMaybe<Scalars['Boolean']>;
  felonyExplanation?: InputMaybe<Scalars['String']>;
  lawsuits?: InputMaybe<Scalars['Boolean']>;
  lawsuitsExplanation?: InputMaybe<Scalars['String']>;
  licenseRevoked?: InputMaybe<Scalars['Boolean']>;
  licenseRevokedExplanation?: InputMaybe<Scalars['String']>;
  maintainedInTrustAccount?: InputMaybe<Scalars['Boolean']>;
};

export type BordereauAgentInformation = {
  __typename?: 'BordereauAgentInformation';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type BordereauApplicationData = {
  __typename?: 'BordereauApplicationData';
  agentInformation?: Maybe<BordereauAgentInformation>;
  clientInformation?: Maybe<BordereauClientInformation>;
  taxes?: Maybe<Array<Maybe<BordereauTax>>>;
};

export type BordereauClientInformation = {
  __typename?: 'BordereauClientInformation';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type BordereauCommission = {
  __typename?: 'BordereauCommission';
  amount?: Maybe<UnitVectorFloat>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type BordereauContractData = {
  __typename?: 'BordereauContractData';
  application?: Maybe<BordereauApplicationData>;
  endDate?: Maybe<Scalars['DateTime']>;
  fees?: Maybe<Array<Maybe<BordereauFee>>>;
  policyId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type BordereauFee = {
  __typename?: 'BordereauFee';
  amount?: Maybe<UnitVectorFloat>;
  name?: Maybe<Scalars['String']>;
};

export type BordereauLocationData = {
  __typename?: 'BordereauLocationData';
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Array<Scalars['String']>>;
};

export type BordereauQuoteData = {
  __typename?: 'BordereauQuoteData';
  commissions?: Maybe<Array<Maybe<BordereauCommission>>>;
  dataset?: Maybe<Scalars['String']>;
  limit?: Maybe<UnitVectorFloat>;
  loadedPremium?: Maybe<UnitVectorFloat>;
  location?: Maybe<BordereauLocationData>;
  programName?: Maybe<ProgramType>;
  unloadedPremium?: Maybe<UnitVectorFloat>;
};

export type BordereauReportInput = {
  startDate?: InputMaybe<Scalars['String']>;
};

export type BordereauReportRecord = {
  __typename?: 'BordereauReportRecord';
  contract?: Maybe<BordereauContractData>;
  quote?: Maybe<BordereauQuoteData>;
  transaction?: Maybe<BordereauTransactionData>;
};

export type BordereauTax = {
  __typename?: 'BordereauTax';
  amount?: Maybe<UnitVectorFloat>;
  name?: Maybe<Scalars['String']>;
};

export type BordereauTransactionData = {
  __typename?: 'BordereauTransactionData';
  paymentAmount?: Maybe<UnitVectorFloat>;
  paymentSentAt?: Maybe<Scalars['DateTime']>;
  rateOfExchange?: Maybe<Scalars['Float']>;
};

export enum BoundType {
  Max = 'Max',
  Min = 'Min',
  Sum = 'Sum',
}

export type BusinessDetails = {
  __typename?: 'BusinessDetails';
  annualRevenue?: Maybe<AnnualRevenue>;
  businessDescription?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
};

export enum BusinessType {
  AgriculturalBusiness = 'AGRICULTURAL_BUSINESS',
  HydropowerGenerationCompany = 'HYDROPOWER_GENERATION_COMPANY',
  IrrigationBusiness = 'IRRIGATION_BUSINESS',
  LandscapingBusiness = 'LANDSCAPING_BUSINESS',
  Other = 'OTHER',
  SupplyChainLogisticsCompany = 'SUPPLY_CHAIN_LOGISTICS_COMPANY',
  WaterSupplyCompany = 'WATER_SUPPLY_COMPANY',
}

export enum CftcnfaRadio {
  AssetsOfTenMillionOrMore = 'ASSETS_OF_TEN_MILLION_OR_MORE',
  CommodityPoolWithFiveMillion = 'COMMODITY_POOL_WITH_FIVE_MILLION',
  NetWorthOfOneMillionOrMore = 'NET_WORTH_OF_ONE_MILLION_OR_MORE',
}

export type ClaimSubmissionReviewInput = {
  isApproved: Scalars['Boolean'];
  reviewBy: Scalars['String'];
  reviewDate: Scalars['String'];
  reviewDescription?: InputMaybe<Scalars['String']>;
};

export type ClaimSubmissionReviewResponse = {
  __typename?: 'ClaimSubmissionReviewResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ClientCheckboxes = {
  __typename?: 'ClientCheckboxes';
  CFTCEightBillion?: Maybe<Scalars['Boolean']>;
  CFTCEntityOwnedByAnotherEntity?: Maybe<Scalars['Boolean']>;
  CFTCNaturalGas?: Maybe<Scalars['Boolean']>;
  CFTCSwapParticipant?: Maybe<Scalars['Boolean']>;
  CFTCfederalAgency?: Maybe<Scalars['Boolean']>;
  CFTCfinancialInNature?: Maybe<Scalars['Boolean']>;
  NYDisputes?: Maybe<Scalars['Boolean']>;
  agreeElectronicClientDisclosure?: Maybe<Scalars['Boolean']>;
  agreeTerrorismDisclosure?: Maybe<Scalars['Boolean']>;
  assessmentAndUnderstanding?: Maybe<Scalars['Boolean']>;
  governingLawAndJurisdictionNY?: Maybe<Scalars['Boolean']>;
  moneyLaunderingCompliance?: Maybe<Scalars['Boolean']>;
  moneyLaunderingProvideInformation?: Maybe<Scalars['Boolean']>;
  nonReliance?: Maybe<Scalars['Boolean']>;
  sanctionsBeneficialOwners?: Maybe<Scalars['Boolean']>;
  sanctionsCitizenSanctionedCountry?: Maybe<Scalars['Boolean']>;
  sanctionsControlledBySanctionedCountry?: Maybe<Scalars['Boolean']>;
  sanctionsOnSanctionsList?: Maybe<Scalars['Boolean']>;
  statusOfParties?: Maybe<Scalars['Boolean']>;
  waiverOfJuryTrial?: Maybe<Scalars['Boolean']>;
};

export type ClientGrpApplicationReviewInput = {
  isApproved: Scalars['Boolean'];
  reviewBy: Scalars['String'];
  reviewDate: Scalars['String'];
  reviewDescription?: InputMaybe<Scalars['String']>;
};

export type ClientGrpApplicationReviewResponse = {
  __typename?: 'ClientGrpApplicationReviewResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ClientInfoApplicationInput = {
  CFTCEightBillion: Scalars['Boolean'];
  CFTCEntityOwnedByAnotherEntity: Scalars['Boolean'];
  CFTCEntityOwnedByAnotherEntityName?: InputMaybe<Scalars['String']>;
  CFTCNaturalGas: Scalars['Boolean'];
  CFTCSwapParticipant: Scalars['Boolean'];
  CFTCfederalAgency: Scalars['Boolean'];
  CFTCfinancialInNature: Scalars['Boolean'];
  NYDisputes: Scalars['Boolean'];
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  agreeElectronicClient: Scalars['Boolean'];
  agreeElectronicClientDisclosure: Scalars['Boolean'];
  agreePrivacyPolicy: Scalars['Boolean'];
  agreeTerrorismDisclosure: Scalars['Boolean'];
  assessmentAndUnderstanding: Scalars['Boolean'];
  businessAddress1: Scalars['String'];
  businessAddress2?: InputMaybe<Scalars['String']>;
  businessCity: Scalars['String'];
  businessCountry: Scalars['String'];
  businessDescription: Scalars['Boolean'];
  businessState: Scalars['String'];
  businessTitle: Scalars['String'];
  businessType: BusinessType;
  businessZipcode: Scalars['String'];
  cftcnfaRadio: CftcnfaRadio;
  city: Scalars['String'];
  companyName: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  entityType: EntityType;
  firstName: Scalars['String'];
  governingLawAndJurisdictionNY: Scalars['Boolean'];
  lastName: Scalars['String'];
  lei?: InputMaybe<Scalars['String']>;
  leiBool?: InputMaybe<Scalars['Boolean']>;
  moneyLaunderingCompliance: Scalars['Boolean'];
  moneyLaunderingProvideInformation: Scalars['Boolean'];
  nfaId?: InputMaybe<Scalars['String']>;
  nonReliance: Scalars['Boolean'];
  phone: Scalars['String'];
  sanctionsBeneficialOwners: Scalars['Boolean'];
  sanctionsCitizenSanctionedCountry: Scalars['Boolean'];
  sanctionsControlledBySanctionedCountry: Scalars['Boolean'];
  sanctionsOnSanctionsList: Scalars['Boolean'];
  signNameCustomer: Scalars['String'];
  state: Scalars['String'];
  statusOfParties: Scalars['Boolean'];
  taxId: Scalars['String'];
  tradeName: Scalars['String'];
  waiverOfJuryTrial: Scalars['Boolean'];
  zipcode: Scalars['String'];
};

export type ClientLpcApplicationReviewInput = {
  isApproved: Scalars['Boolean'];
  reviewBy: Scalars['String'];
  reviewDate: Scalars['String'];
  reviewDescription?: InputMaybe<Scalars['String']>;
};

export type ClientLpcApplicationReviewResponse = {
  __typename?: 'ClientLpcApplicationReviewResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ClientUserInformation = {
  __typename?: 'ClientUserInformation';
  CFTCEntityOwnedByAnotherEntityName?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  businessAddress?: Maybe<Address>;
  businessDescription?: Maybe<Scalars['String']>;
  businessTitle?: Maybe<Scalars['String']>;
  cftcnfaRadio?: Maybe<Scalars['String']>;
  checkboxes?: Maybe<ClientCheckboxes>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  entityInformation?: Maybe<EntityInformation>;
  entityType?: Maybe<EntityType>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lei?: Maybe<Scalars['String']>;
  leiBool?: Maybe<Scalars['Boolean']>;
  nfaId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  tradeName?: Maybe<Scalars['String']>;
};

export type CommissionInputs = {
  arbol?: InputMaybe<Scalars['Float']>;
  broker?: InputMaybe<Scalars['Float']>;
  counterParty?: InputMaybe<Scalars['Float']>;
  dataBroker?: InputMaybe<Scalars['Float']>;
  total?: InputMaybe<Scalars['Float']>;
};

export type CompanyAddress = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CompanyAddresses = {
  mailingAddress?: InputMaybe<CompanyAddress>;
  physicalAddress?: InputMaybe<CompanyAddress>;
};

export type CompanyHistory = {
  __typename?: 'CompanyHistory';
  hasConvictedFelony?: Maybe<Scalars['Boolean']>;
  hasConvictedFelonyDescription?: Maybe<Scalars['String']>;
  hasDischargedBankruptcyLastFiveYears?: Maybe<Scalars['Boolean']>;
  hasDischargedBankruptcyLastFiveYearsDescription?: Maybe<Scalars['String']>;
  hasDishonestyMisdemeanor?: Maybe<Scalars['Boolean']>;
  hasDishonestyMisdemeanorDescription?: Maybe<Scalars['String']>;
  hasLawsuitsLastThreeYears?: Maybe<Scalars['Boolean']>;
  hasLawsuitsLastThreeYearsDescription?: Maybe<Scalars['String']>;
  hasLicenseRevokedLastThreeYears?: Maybe<Scalars['Boolean']>;
  hasLicenseRevokedLastThreeYearsDescription?: Maybe<Scalars['String']>;
};

export type CompleteAgencyOnboardingInputs = {
  documents: Array<OrgDocumentInput>;
};

export type CompleteAgencyOnboardingResponse = {
  __typename?: 'CompleteAgencyOnboardingResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Contract = {
  _id?: Maybe<Scalars['ID']>;
  capitalProvider?: Maybe<Scalars['String']>;
  claim?: Maybe<Form>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerCounterparty?: Maybe<User>;
  documents?: Maybe<Array<Maybe<File>>>;
  evaluationData?: Maybe<IndexData>;
  hasFinalTaxes?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<History>>>;
  pausedAt?: Maybe<Scalars['DateTime']>;
  pausedBy?: Maybe<User>;
  pausedReason?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  reviewedBy?: Maybe<User>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ContractDiscriminatorType {
  HybridhomeownersContract = 'HYBRIDHOMEOWNERS_CONTRACT',
  ParametricContract = 'PARAMETRIC_CONTRACT',
}

export enum ContractTradeStructureTypes {
  Basket = 'BASKET',
  Normal = 'NORMAL',
}

export type ContractUserInformation = {
  __typename?: 'ContractUserInformation';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CreateClientUserInputs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CreateGrpInsuranceQuoteInputs = {
  dataset: Scalars['String'];
  end: Scalars['String'];
  limit: Scalars['Float'];
  start: Scalars['String'];
  units: Scalars['String'];
  weightedLocations: Array<WeightedLocations>;
};

export type CreateHhApplicationInputs = {
  diligentSearchUpload: Scalars['String'];
  signatureUpload: Scalars['String'];
};

export type CreateHybridHomeownersQuoteInputs = {
  allOtherPerils: Scalars['Float'];
  animalLiability: Scalars['Boolean'];
  aopDeductible: Scalars['Float'];
  carportAmount?: InputMaybe<Scalars['Float']>;
  carportYearBuilt?: InputMaybe<Scalars['Int']>;
  hurricaneDeductible: UnitVectorFloatInputs;
  limitedFungiLimit: LimitedFungiLimitInterval;
  limitedWaterDamage: Scalars['Float'];
  lossAssessmentAmount: Scalars['Float'];
  lossOfUse: Scalars['Float'];
  medicalPayments: Scalars['Float'];
  mold?: InputMaybe<Scalars['Float']>;
  ordinanceOfLawPercentage: Scalars['Float'];
  otherStructuresPercentage: Scalars['Float'];
  personalInjury: Scalars['Boolean'];
  personalLiability: Scalars['Float'];
  personalPropertyPercentage: Scalars['Float'];
  personalPropertyReplacement: Scalars['Boolean'];
  propertyValue: Scalars['Float'];
  rentedDwelling: Scalars['Boolean'];
  rentedLiability: Scalars['Boolean'];
  requestId: Scalars['String'];
  roofAdjustmentLimit: Scalars['Int'];
  roofDeductible: UnitVectorFloatInputs;
  startDate: Scalars['String'];
  waterBackup: Scalars['Boolean'];
  waterDeductible: Scalars['Float'];
  waterExclusion: Scalars['Boolean'];
};

export type CreateLegacyClientInputs = {
  address?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type CreateLegacyContractInputs = {
  clientCompany?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['String']>;
  contractName?: InputMaybe<Scalars['String']>;
  currencyUnit?: InputMaybe<Scalars['String']>;
  direct?: InputMaybe<Scalars['String']>;
  indexType?: InputMaybe<Scalars['String']>;
  lcType?: InputMaybe<Scalars['String']>;
  optionType?: InputMaybe<Scalars['String']>;
  peril?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  salesAgentId?: InputMaybe<Scalars['String']>;
  sfOpportunityId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type CreateOrgAdminInputs = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateOrganizationInputs = {
  affiliatedTradeAssociations?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  backgroundDetails?: InputMaybe<BackgroundDetailsInputs>;
  coverAllLocations?: InputMaybe<Scalars['Boolean']>;
  documents?: InputMaybe<Array<OrgDocumentInput>>;
  fein?: InputMaybe<Scalars['String']>;
  hasSurplusLinesLicense?: InputMaybe<Scalars['Boolean']>;
  licensedProducers?: InputMaybe<Array<InputMaybe<OrgLicensedProducerInputs>>>;
  licenses?: InputMaybe<Array<OrgLicenseInput>>;
  locations?: InputMaybe<Array<InputMaybe<OrgLocationInputs>>>;
  name?: InputMaybe<Scalars['String']>;
  producerType?: InputMaybe<OrganizationProducerType>;
  signature?: InputMaybe<Scalars['String']>;
  signingDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  tradeNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  website?: InputMaybe<Scalars['String']>;
  yearsInBusiness?: InputMaybe<Scalars['Int']>;
};

export type CreateQuoteInputs = {
  collateral?: InputMaybe<Scalars['Int']>;
  coverages?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  dataset: Scalars['String'];
  end: Scalars['String'];
  exhaust?: InputMaybe<Scalars['Float']>;
  lat: Scalars['Float'];
  lc_end?: InputMaybe<Scalars['String']>;
  lc_start?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  locationCity: Scalars['String'];
  locationState: Scalars['String'];
  lon: Scalars['Float'];
  option_type?: InputMaybe<OptionType>;
  start: Scalars['String'];
  strike?: InputMaybe<Scalars['Float']>;
  units: Scalars['String'];
  weight?: Scalars['Float'];
};

export enum CurrencyType {
  Aud = 'AUD',
  Chf = 'CHF',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Rub = 'RUB',
  Sgd = 'SGD',
  Usd = 'USD',
}

export type Dataset = {
  __typename?: 'Dataset';
  dclimateId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum DatasetCollection {
  Chirps = 'CHIRPS',
  Cpc = 'CPC',
  Era5 = 'ERA5',
  Era5Land = 'ERA5_Land',
  Prism = 'PRISM',
}

export type DayVector = {
  day?: InputMaybe<Scalars['String']>;
  vector?: InputMaybe<Scalars['Float']>;
};

export enum DeleteType {
  Hard = 'HARD',
  Soft = 'SOFT',
}

export type DerivativesOverdueTransaction = {
  __typename?: 'DerivativesOverdueTransaction';
  _id?: Maybe<Scalars['String']>;
  contract?: Maybe<IdAndName>;
  contractID?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  dueDateAsRealDate?: Maybe<Scalars['DateTime']>;
  paid?: Maybe<Scalars['Boolean']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionContext?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DocumentType>;
  upload?: Maybe<Scalars['String']>;
};

export enum DocumentType {
  AgencyEo = 'AGENCY_EO',
  AgencyLicense = 'AGENCY_LICENSE',
  AgencyW9 = 'AGENCY_W9',
  AgentLicense = 'AGENT_LICENSE',
  Misc = 'MISC',
  ProducerAgreement = 'PRODUCER_AGREEMENT',
  WholesalerAddendum = 'WHOLESALER_ADDENDUM',
}

export type EntityInformation = {
  __typename?: 'EntityInformation';
  address?: Maybe<Address>;
  businessDetails?: Maybe<BusinessDetails>;
  contact?: Maybe<EntityInformationContact>;
  diligentSearchUpload?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type EntityInformationContact = {
  __typename?: 'EntityInformationContact';
  additionalEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export enum EntityType {
  Corporation = 'CORPORATION',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  Partnership = 'PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  SCorporation = 'S_CORPORATION',
}

export type Evaluation = {
  __typename?: 'Evaluation';
  contractId?: Maybe<Scalars['String']>;
  indexData?: Maybe<Scalars['JSON']>;
  payout?: Maybe<Scalars['Float']>;
  riskContractId?: Maybe<Scalars['String']>;
};

export type EvaluationData = {
  dataset?: InputMaybe<Scalars['String']>;
  indexData?: InputMaybe<Scalars['JSON']>;
};

export type EvaluationLegacyContractResult = {
  __typename?: 'EvaluationLegacyContractResult';
  message?: Maybe<Scalars['String']>;
  result?: Maybe<Array<Maybe<LegacyRiskEvaluationResult>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ExecuteContractInputs = {
  contractId: Scalars['String'];
  counterpartyName: Scalars['String'];
  quoteIds: Array<Scalars['String']>;
};

export type ExecuteContractResult = {
  __typename?: 'ExecuteContractResult';
  message?: Maybe<Scalars['String']>;
  result?: Maybe<Array<Maybe<RiskExecutionResult>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ExecuteLegacyDerivativeContractInputs = {
  arbolId: Scalars['String'];
  capitalProviders: Scalars['String'];
  counterparty: Scalars['String'];
  currency: CurrencyType;
  grossPremium: Scalars['Float'];
  indexType: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  quoteId: Scalars['String'];
};

export type ExecuteLegacyDerivativeContractResult = {
  __typename?: 'ExecuteLegacyDerivativeContractResult';
  message?: Maybe<Scalars['String']>;
  result?: Maybe<Array<Maybe<LegacyDerivativeExecutionResult>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ExecuteLegacyRiskContractResult = {
  __typename?: 'ExecuteLegacyRiskContractResult';
  message?: Maybe<Scalars['String']>;
  result?: Maybe<Array<Maybe<LegacyRiskExecutionResult>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Feature = {
  __typename?: 'Feature';
  geometry?: Maybe<Geometry>;
  properties?: Maybe<Properties>;
  type: GeoJsonTypes;
};

export type FeatureCollection = {
  __typename?: 'FeatureCollection';
  features: Array<Maybe<Feature>>;
  type: GeoJsonTypes;
};

export type Feedback = {
  __typename?: 'Feedback';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  submittedBy?: Maybe<User>;
};

export type File = {
  __typename?: 'File';
  _id?: Maybe<Scalars['ID']>;
  bucketName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type FileStorageInputs = {
  bucketName: S3Bucket;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['String']>;
};

export type ForeignCurrencySupport = {
  __typename?: 'ForeignCurrencySupport';
  conversionDate?: Maybe<Scalars['DateTime']>;
  estimateConversionFees?: Maybe<Scalars['Float']>;
  estimatedConversionDate?: Maybe<Scalars['DateTime']>;
  estimatedCurrencyConversionRate?: Maybe<Scalars['Float']>;
  exactCurrencyConversionRate?: Maybe<Scalars['Float']>;
  finalConversionFees?: Maybe<Scalars['Float']>;
  usdAmount?: Maybe<Scalars['Float']>;
  usdEstimate?: Maybe<Scalars['Float']>;
};

export type Form = {
  __typename?: 'Form';
  _id?: Maybe<Scalars['ID']>;
  contractId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<Maybe<File>>>;
  lossReport?: Maybe<LossReport>;
  orgId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  reviewDetails?: Maybe<FormReviewDetails>;
  status?: Maybe<FormStatus>;
  type?: Maybe<FormType>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  values?: Maybe<Scalars['JSON']>;
};

export type FormReviewDetails = {
  __typename?: 'FormReviewDetails';
  isApproved?: Maybe<Scalars['Boolean']>;
  reviewBy?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['String']>;
  reviewDescription?: Maybe<Scalars['String']>;
};

export enum FormStatus {
  Approved = 'APPROVED',
  Complete = 'COMPLETE',
  Draft = 'DRAFT',
  NeedsReview = 'NEEDS_REVIEW',
  Rejected = 'REJECTED',
}

export enum FormType {
  LossReport = 'LOSS_REPORT',
  ProducerRegistration = 'PRODUCER_REGISTRATION',
}

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GeoJson = Feature | FeatureCollection | Geometry;

export enum GeoJsonTypes {
  Feature = 'Feature',
  FeatureCollection = 'FeatureCollection',
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon',
}

export type Geometry = {
  __typename?: 'Geometry';
  coordinates: Scalars['JSON'];
  type: GeoJsonTypes;
};

export type GetFileInputs = {
  bucketName: Scalars['String'];
  key: Scalars['String'];
};

export type GetIndexDataInput = {
  dataset: Scalars['String'];
  end: Scalars['String'];
  start: Scalars['String'];
  units: Scalars['String'];
  weightedLocations: Array<WeightedLocations>;
};

export type GetIndexDataInputV2 = {
  collection: DatasetCollection;
  dataset: Scalars['String'];
  end: Scalars['String'];
  start: Scalars['String'];
  units: Scalars['String'];
  weightedLocations: Array<WeightedLocations>;
};

export type GetIndexDataResponse = {
  __typename?: 'GetIndexDataResponse';
  average?: Maybe<Scalars['Float']>;
  exhaust?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  result?: Maybe<Array<Maybe<GetIndexDataResponseResult>>>;
};

export type GetIndexDataResponseResult = {
  __typename?: 'GetIndexDataResponseResult';
  temp?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['String']>;
};

export type GetLtcIndexDataInput = {
  dataset: Scalars['String'];
  end: Scalars['String'];
  start: Scalars['String'];
  units: Scalars['String'];
  weightedLocations: Array<WeightedLocations>;
};

export type GetLtcIndexDataResponse = {
  __typename?: 'GetLTCIndexDataResponse';
  average?: Maybe<Scalars['Float']>;
  exhaust?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  result?: Maybe<Array<Maybe<GetLtcIndexDataResponseResult>>>;
};

export type GetLtcIndexDataResponseResult = {
  __typename?: 'GetLTCIndexDataResponseResult';
  temp?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['String']>;
};

export type GrpInsuranceApplicationBusinessDetails = {
  annualRevenueCurrency?: InputMaybe<Scalars['String']>;
  businessDescription?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<BusinessType>;
  revenueYears?: InputMaybe<Array<InputMaybe<YearRevenueInput>>>;
};

export type GrpInsuranceApplicationContact = {
  additionalEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type GrpInsuranceApplicationEntityInformation = {
  address?: InputMaybe<AddressInputs>;
  contact?: InputMaybe<GrpInsuranceApplicationContact>;
  legalName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
};

export type HhOrganizationProspectRequest = {
  __typename?: 'HhOrganizationProspectRequest';
  _id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HhQuoteProspectRequest = {
  __typename?: 'HhQuoteProspectRequest';
  _id?: Maybe<Scalars['ID']>;
  agreeToDiligenceSearch?: Maybe<Scalars['Boolean']>;
  brokerAgentId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  property?: Maybe<Scalars['JSON']>;
  quotes?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  startDate?: Maybe<Scalars['DateTime']>;
  underWritingChecks?: Maybe<Array<Maybe<UnderWritingCheckOption>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HistoricalPremiumVolumesInput = {
  commercialPC?: InputMaybe<Scalars['Float']>;
  personalLines?: InputMaybe<Scalars['Float']>;
  totalPremium?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type History = {
  __typename?: 'History';
  audience?: Maybe<Array<Maybe<Role>>>;
  description?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['DateTime']>;
  eventName?: Maybe<Scalars['String']>;
  eventType?: Maybe<HistoryEventType>;
};

export enum HistoryEventType {
  AgentSignature = 'AGENT_SIGNATURE',
  ApplicationApproved = 'APPLICATION_APPROVED',
  ApplicationPaused = 'APPLICATION_PAUSED',
  ApplicationRejected = 'APPLICATION_REJECTED',
  ApplicationResumed = 'APPLICATION_RESUMED',
  ClaimApproved = 'CLAIM_APPROVED',
  ClaimRejected = 'CLAIM_REJECTED',
  ClaimSubmitted = 'CLAIM_SUBMITTED',
  ClientSignature = 'CLIENT_SIGNATURE',
  CreatedAt = 'CREATED_AT',
  Evaluated = 'EVALUATED',
  NpnFailed = 'NPN_FAILED',
  OfacFailed = 'OFAC_FAILED',
  OfacFailCleared = 'OFAC_FAIL_CLEARED',
  PaymentSubmitted = 'PAYMENT_SUBMITTED',
  PolicyIssued = 'POLICY_ISSUED',
  PremiumIndicationCreated = 'PREMIUM_INDICATION_CREATED',
  UploadDiligentEffort = 'UPLOAD_DILIGENT_EFFORT',
}

export type Hjord_ResultOutput = {
  __typename?: 'Hjord_ResultOutput';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type Hjord_SubmitTradeExecutionRequest = {
  address?: InputMaybe<Scalars['String']>;
  agrees_to_governing_law?: InputMaybe<Scalars['Boolean']>;
  business_type?: InputMaybe<Scalars['String']>;
  consents_to_e_signature?: InputMaybe<Scalars['Boolean']>;
  consents_to_jurisdiction?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  entity_name?: InputMaybe<Scalars['String']>;
  entity_type?: InputMaybe<Scalars['String']>;
  esig_by?: InputMaybe<Scalars['String']>;
  esig_date?: InputMaybe<Scalars['String']>;
  esig_name?: InputMaybe<Scalars['String']>;
  esig_title?: InputMaybe<Scalars['String']>;
  exhaust?: InputMaybe<Scalars['Float']>;
  federal_tax_id?: InputMaybe<Scalars['String']>;
  follows_anti_money_laundering_laws?: InputMaybe<Scalars['Boolean']>;
  has_commodity_pool_asset5mil_with_operator?: InputMaybe<Scalars['Boolean']>;
  has_net_worth1mil_with_hedge_commercial_risk?: InputMaybe<Scalars['Boolean']>;
  has_no_beneficial_owner_on_sanctions_list?: InputMaybe<Scalars['Boolean']>;
  has_total_assets10mil?: InputMaybe<Scalars['Boolean']>;
  is_hedging_low_yield_risk?: InputMaybe<Scalars['Boolean']>;
  is_independent_decision?: InputMaybe<Scalars['Boolean']>;
  is_not_citizen_of_sanctioned_country?: InputMaybe<Scalars['Boolean']>;
  is_not_controlled_different_entity?: InputMaybe<Scalars['Boolean']>;
  is_not_energy_entity?: InputMaybe<Scalars['Boolean']>;
  is_not_financial_entity?: InputMaybe<Scalars['Boolean']>;
  is_not_government_entity?: InputMaybe<Scalars['Boolean']>;
  is_not_major_swap_participant?: InputMaybe<Scalars['Boolean']>;
  is_not_on_sanctions_list?: InputMaybe<Scalars['Boolean']>;
  is_not_owned_by_sanctioned_country?: InputMaybe<Scalars['Boolean']>;
  lei?: InputMaybe<Scalars['String']>;
  max_payment_amount?: InputMaybe<Scalars['Float']>;
  no_lei_disclaimer?: InputMaybe<Scalars['Boolean']>;
  none_of_the_above_contract_eligibility?: InputMaybe<Scalars['Boolean']>;
  other_counter_party_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  premium?: InputMaybe<Scalars['Float']>;
  referrer_email?: InputMaybe<Scalars['String']>;
  strike?: InputMaybe<Scalars['Float']>;
  swap_does_not_exceed8_billion_annual?: InputMaybe<Scalars['Boolean']>;
  trade_id?: InputMaybe<Scalars['String']>;
  understands_arbol_is_not_advisor?: InputMaybe<Scalars['Boolean']>;
  understands_term_conditions_risks?: InputMaybe<Scalars['Boolean']>;
  waives_jury_trail?: InputMaybe<Scalars['Boolean']>;
  will_provide_info_upon_request?: InputMaybe<Scalars['Boolean']>;
};

export type HybridHomeownersApplication = {
  __typename?: 'HybridHomeownersApplication';
  premium?: Maybe<UnitVectorFloat>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
};

export type HybridHomeownersContract = Contract & {
  __typename?: 'HybridHomeownersContract';
  _id?: Maybe<Scalars['ID']>;
  application?: Maybe<HybridHomeownersApplication>;
  capitalProvider?: Maybe<Scalars['String']>;
  claim?: Maybe<Form>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerCounterparty?: Maybe<User>;
  documents?: Maybe<Array<Maybe<File>>>;
  evaluationData?: Maybe<IndexData>;
  hasFinalTaxes?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<History>>>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lifecycleStage?: Maybe<Scalars['String']>;
  pausedAt?: Maybe<Scalars['DateTime']>;
  pausedBy?: Maybe<User>;
  pausedReason?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Maybe<HybridHomeownersQuote>>>;
  reviewedBy?: Maybe<User>;
  salesAgent?: Maybe<User>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HybridHomeownersCoverageStructure = {
  __typename?: 'HybridHomeownersCoverageStructure';
  allOtherPerils?: Maybe<UnitVectorFloat>;
  animalLiability?: Maybe<Scalars['Boolean']>;
  aopDeductible?: Maybe<UnitVectorFloat>;
  carportAmount?: Maybe<Scalars['Int']>;
  carportYearBuilt?: Maybe<Scalars['Int']>;
  hurricaneDeductible?: Maybe<UnitVectorFloat>;
  limitedFungiLimit?: Maybe<LimitedFungiLimitInterval>;
  limitedWaterDamage?: Maybe<UnitVectorFloat>;
  lossAssessmentAmount?: Maybe<UnitVectorFloat>;
  lossOfUse?: Maybe<UnitVectorFloat>;
  medicalPayments?: Maybe<UnitVectorFloat>;
  mold?: Maybe<UnitVectorFloat>;
  ordinanceOfLawPercentage?: Maybe<UnitVectorFloat>;
  otherStructuresPercentage?: Maybe<UnitVectorFloat>;
  personalInjury?: Maybe<Scalars['Boolean']>;
  personalLiability?: Maybe<UnitVectorFloat>;
  personalPropertyPercentage?: Maybe<UnitVectorFloat>;
  personalPropertyReplacement?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyQuoteDetails>;
  propertyRef?: Maybe<Scalars['ID']>;
  propertyValue?: Maybe<UnitVectorFloat>;
  rentedDwelling?: Maybe<Scalars['Boolean']>;
  rentedLiability?: Maybe<Scalars['Boolean']>;
  roofAdjustmentLimit?: Maybe<UnitVectorFloat>;
  roofDeductible?: Maybe<UnitVectorFloat>;
  startDate?: Maybe<Scalars['String']>;
  waterBackup?: Maybe<Scalars['Boolean']>;
  waterDeductible?: Maybe<UnitVectorFloat>;
  waterExclusion?: Maybe<Scalars['Boolean']>;
};

export type HybridHomeownersPrices = {
  __typename?: 'HybridHomeownersPrices';
  fullPremium?: Maybe<UnitVectorFloat>;
  monthlyPremium?: Maybe<UnitVectorFloat>;
};

export type HybridHomeownersQuote = Quote & {
  __typename?: 'HybridHomeownersQuote';
  _id?: Maybe<Scalars['ID']>;
  contract?: Maybe<HybridHomeownersContract>;
  coverageStructure?: Maybe<HybridHomeownersCoverageStructure>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCounterparty?: Maybe<User>;
  history?: Maybe<Array<Maybe<History>>>;
  prices?: Maybe<HybridHomeownersPrices>;
  requestId?: Maybe<Scalars['String']>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IdAndName = {
  __typename?: 'IdAndName';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IndexData = {
  __typename?: 'IndexData';
  _id?: Maybe<Scalars['ID']>;
  contractId: Scalars['String'];
  dataset?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  evaluationData?: Maybe<Scalars['JSON']>;
  location?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type IndexUnitHistory = {
  history?: InputMaybe<Array<InputMaybe<DayVector>>>;
  unit?: InputMaybe<Scalars['String']>;
};

export type InitializeLegacyMeteorContractInputs = {
  bespokeDataRequired?: InputMaybe<Scalars['Boolean']>;
  brokerCommission?: InputMaybe<Scalars['Float']>;
  brokerCommissionCurrency?: InputMaybe<Scalars['String']>;
  brokerLegacyAccountId?: InputMaybe<Scalars['String']>;
  brokerName?: InputMaybe<Scalars['String']>;
  brokerSfAccountId?: InputMaybe<Scalars['String']>;
  businessSector: Scalars['String'];
  capitalProviders: Scalars['String'];
  clientLegacyAccountId?: InputMaybe<Scalars['String']>;
  clientName: Scalars['String'];
  clientSfAccountId: Scalars['String'];
  contractName: Scalars['String'];
  currency: CurrencyType;
  dataCorrectionDays: Scalars['Int'];
  end: Scalars['String'];
  grossPremium: Scalars['Float'];
  indexType: Scalars['String'];
  lat: Scalars['Float'];
  lcRequired: Scalars['Boolean'];
  limit: Scalars['Float'];
  locationName?: InputMaybe<Scalars['String']>;
  lon: Scalars['Float'];
  optionType: Scalars['String'];
  peril: Scalars['String'];
  programName: Scalars['String'];
  quoteId: Scalars['String'];
  sfOpportunityId: Scalars['String'];
  start: Scalars['String'];
  strike: Scalars['Float'];
  tradeDate: Scalars['String'];
  weather_metadata: WeatherMetadataInput;
};

export type InviteAuthorizedContactInputs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type InviteClientClaimFormResponse = {
  __typename?: 'InviteClientClaimFormResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type InviteOrgAgentInputs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  orgContactType?: InputMaybe<Role>;
  stateLicenseNumber: Scalars['String'];
};

export type InviteResponse = {
  __typename?: 'InviteResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type LedgerEntry = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  fxRate?: InputMaybe<Scalars['Float']>;
  native?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  usd?: InputMaybe<Scalars['Float']>;
};

export type LegacyContractPaginationInputs = {
  contractName?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  endDateFrom?: InputMaybe<Scalars['String']>;
  endDateTo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  startDateFrom?: InputMaybe<Scalars['String']>;
  startDateTo?: InputMaybe<Scalars['String']>;
  tradeDateFrom?: InputMaybe<Scalars['String']>;
  tradeDateTo?: InputMaybe<Scalars['String']>;
};

export type LegacyDataCorrectionContract = {
  __typename?: 'LegacyDataCorrectionContract';
  _id?: Maybe<Scalars['String']>;
  correctionPeriodEndDate?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  finalIndexes?: Maybe<Array<Maybe<Scalars['Float']>>>;
  name?: Maybe<Scalars['String']>;
  payout?: Maybe<Scalars['Float']>;
  premium?: Maybe<Scalars['Float']>;
};

export type LegacyDerivativeExecutionResult = {
  __typename?: 'LegacyDerivativeExecutionResult';
  contractId?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['String']>;
  riskContractId?: Maybe<Scalars['String']>;
};

export type LegacyEvaluatedContract = {
  __typename?: 'LegacyEvaluatedContract';
  arbolId?: Maybe<Scalars['String']>;
  counterparty?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payout?: Maybe<Scalars['Float']>;
  portfolio?: Maybe<Array<Maybe<LegacyEvaluatedContractPortfolio>>>;
  premium?: Maybe<Scalars['Float']>;
};

export type LegacyEvaluatedContractPortfolio = {
  __typename?: 'LegacyEvaluatedContractPortfolio';
  estimatedEp?: Maybe<Scalars['Float']>;
  estimatedStDev?: Maybe<Scalars['Float']>;
  finalIndex?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  payoutZscore?: Maybe<Scalars['Float']>;
};

export type LegacyEvaluationContract = {
  __typename?: 'LegacyEvaluationContract';
  _id?: Maybe<Scalars['String']>;
  bespokeDataRequired?: Maybe<Scalars['Boolean']>;
  counterparty?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['String']>;
  weather_metadata?: Maybe<WeatherMetadata>;
};

export type LegacyEvaluationInputs = {
  arbolId: Scalars['String'];
  name: Scalars['String'];
};

export enum LegacyEvaluationType {
  Correction = 'CORRECTION',
  Original = 'ORIGINAL',
}

export type LegacyRiskEvaluationResult = {
  __typename?: 'LegacyRiskEvaluationResult';
  contractId?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['String']>;
  riskContractId?: Maybe<Scalars['String']>;
};

export type LegacyRiskExecutionResult = {
  __typename?: 'LegacyRiskExecutionResult';
  contractId?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['String']>;
  riskContractId?: Maybe<Scalars['String']>;
};

export enum LegacySourceType {
  Offline = 'offline',
}

export enum LegacyUnpaidContractType {
  Commission = 'COMMISSION',
  Payout = 'PAYOUT',
  Premium = 'PREMIUM',
}

export enum LegacyUserType {
  Agent = 'AGENT',
  Client = 'CLIENT',
}

export enum LifecycleStage {
  AwaitingApplication = 'AWAITING_APPLICATION',
  AwaitingEvaluation = 'AWAITING_EVALUATION',
  AwaitingPayment = 'AWAITING_PAYMENT',
  Cancelled = 'CANCELLED',
  Evaluated = 'EVALUATED',
  InProgress = 'IN_PROGRESS',
  LivePolicy = 'LIVE_POLICY',
  PendingPaymentProcessing = 'PENDING_PAYMENT_PROCESSING',
  PolicyIssued = 'POLICY_ISSUED',
  PreApplication = 'PRE_APPLICATION',
}

export enum LimitedFungiLimitInterval {
  FifteenToThirtyThousand = 'FIFTEEN_TO_THIRTY_THOUSAND',
  FiftyThousand = 'FIFTY_THOUSAND',
  TenToTwentyThousand = 'TEN_TO_TWENTY_THOUSAND',
  TwentyFiveToFiftyThousand = 'TWENTY_FIVE_TO_FIFTY_THOUSAND',
}

export type LossReport = {
  __typename?: 'LossReport';
  address1?: Maybe<Scalars['String']>;
  agreeElectronicNamedInsured?: Maybe<Scalars['Boolean']>;
  basisDescription?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationDate?: Maybe<Scalars['DateTime']>;
  payoutMethod?: Maybe<PaymentMethod>;
  policyNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type LpcInsuranceApplicationBusinessDetails = {
  annualRevenueCurrency?: InputMaybe<Scalars['String']>;
  businessDescription?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<BusinessType>;
  revenueYears?: InputMaybe<Array<InputMaybe<YearRevenueInput>>>;
};

export type LpcInsuranceApplicationContact = {
  additionalEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type LpcInsuranceApplicationEntityInformation = {
  address?: InputMaybe<AddressInputs>;
  contact?: InputMaybe<LpcInsuranceApplicationContact>;
  legalName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptClaimsFormTokenInvite?: Maybe<AcceptClaimsFormTokenInviteResponse>;
  acceptClientReviewContractInvite?: Maybe<AcceptClientReviewContractInviteResponse>;
  addAgentPaymentInformation?: Maybe<User>;
  addAppPermission?: Maybe<User>;
  addClientUserPayment?: Maybe<User>;
  addProperty?: Maybe<Property>;
  approveAgent?: Maybe<User>;
  approveLegacyContract?: Maybe<Scalars['JSON']>;
  approveLegacyTransaction?: Maybe<Scalars['JSON']>;
  attachClientToQuote?: Maybe<Quote>;
  authorize?: Maybe<AuthUserPayload>;
  authorizeAgencyOnboardingUser?: Maybe<AuthorizeAgencyOnboardingUserResponse>;
  changePassword?: Maybe<AuthUserPayload>;
  claimSubmissionReview?: Maybe<ClaimSubmissionReviewResponse>;
  clientApplicationVerification?: Maybe<Contract>;
  clientLpcApplicationReview?: Maybe<ClientLpcApplicationReviewResponse>;
  completeAgencyOnboarding?: Maybe<CompleteAgencyOnboardingResponse>;
  confirmUser?: Maybe<AuthUserPayload>;
  createApplication?: Maybe<Contract>;
  createClientUser?: Maybe<User>;
  createHHApplication?: Maybe<Contract>;
  createHHQuote?: Maybe<Quote>;
  createHHQuoteProspectRequest?: Maybe<HhQuoteProspectRequest>;
  createInsuranceApplication?: Maybe<Contract>;
  createLegacyClient?: Maybe<Scalars['JSON']>;
  createLegacyContract?: Maybe<Scalars['JSON']>;
  createLoginToken?: Maybe<Scalars['String']>;
  createOrgAdmin?: Maybe<User>;
  createOrganization?: Maybe<Organization>;
  createParametricQuote?: Maybe<Array<Maybe<ParametricQuote>>>;
  createPremiumIndicationRequest?: Maybe<GenericResponse>;
  createQuote?: Maybe<Array<Maybe<Quote>>>;
  deleteHHQuoteProspectRequest?: Maybe<HhQuoteProspectRequest>;
  deletePremiumRequest?: Maybe<PremiumRequest>;
  deleteProperty?: Maybe<Property>;
  deleteQuote?: Maybe<Quote>;
  deleteQuotes?: Maybe<Array<Maybe<Quote>>>;
  emailPolicyDocument?: Maybe<Scalars['Boolean']>;
  executeContract?: Maybe<ExecuteContractResult>;
  executeLegacyDerivativeContract?: Maybe<ExecuteLegacyDerivativeContractResult>;
  executeLegacyRiskContract?: Maybe<ExecuteLegacyRiskContractResult>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  getLegacySignedUrl?: Maybe<Scalars['JSON']>;
  getSignedBdxUrl: Scalars['String'];
  getSignedUrl: Scalars['String'];
  initializeLegacyMeteorContract?: Maybe<Scalars['JSON']>;
  insertPremiumRequest?: Maybe<PremiumRequest>;
  inviteAuthorizedContact?: Maybe<InviteResponse>;
  inviteClientClaimForm?: Maybe<InviteClientClaimFormResponse>;
  inviteLpcOrgAgent?: Maybe<InviteResponse>;
  inviteOrgAgent?: Maybe<InviteResponse>;
  login?: Maybe<AuthUserPayload>;
  pauseApplication?: Maybe<Contract>;
  registerHubUser?: Maybe<AuthUserPayload>;
  registerOrgAgent?: Maybe<User>;
  registerUser?: Maybe<AuthUserPayload>;
  resendConfirm?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<AuthUserPayload>;
  reviewProducerRegistrationForm?: Maybe<ReviewProducerRegistrationFormResponse>;
  reviewProperty?: Maybe<ReviewPropertyResponse>;
  saveFeedback?: Maybe<Feedback>;
  saveHHQuotes?: Maybe<Array<Maybe<Quote>>>;
  saveLPCInsuranceQuote?: Maybe<ParametricQuote>;
  saveLTCInsuranceQuote?: Maybe<ParametricQuote>;
  saveLossReport?: Maybe<Form>;
  saveProducerRegistration?: Maybe<Form>;
  saveQuote?: Maybe<Quote>;
  sendAggregatedPayoutReport?: Maybe<Scalars['String']>;
  sendBespokeDataAlert?: Maybe<Scalars['String']>;
  sendDerivativesOverdueTransactionAlert?: Maybe<Scalars['String']>;
  sendFinalDataCorrectionAlert?: Maybe<Scalars['String']>;
  sendPrelimDataCorrectionAlert?: Maybe<Scalars['String']>;
  sendUpcomingCalculationDateReport?: Maybe<Scalars['String']>;
  setEvaluation?: Maybe<Scalars['String']>;
  setLegacyEvaluation?: Maybe<Scalars['JSON']>;
  shareLtcInsuranceQuote?: Maybe<Scalars['Boolean']>;
  sharePrecipInsuranceQuote?: Maybe<Scalars['Boolean']>;
  sharePremiumRequest?: Maybe<GenericResponse>;
  submitLpcInsuranceApplication?: Maybe<Contract>;
  submitPremiumRequest?: Maybe<ParametricQuote>;
  submitProducerRegistration?: Maybe<Organization>;
  submitReviewedLpcApplicationByClient?: Maybe<Contract>;
  submitTradeExecutionRequest?: Maybe<Hjord_ResultOutput>;
  underwritingCheckHH?: Maybe<ReviewUnderwritingCheck>;
  unpauseApplication?: Maybe<Contract>;
  updateClientEmail?: Maybe<User>;
  updateClientUser?: Maybe<User>;
  updateHHQuoteProspectRequest?: Maybe<HhQuoteProspectRequest>;
  updateLegacyContract?: Maybe<Scalars['JSON']>;
  updateLegacyTransaction?: Maybe<Scalars['JSON']>;
  updateLpcInsuranceApplication?: Maybe<Contract>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationContact?: Maybe<Organization>;
  updateOrganizationLocationAddress?: Maybe<Organization>;
  updatePremiumRequest?: Maybe<PremiumRequest>;
  updateProperty?: Maybe<Property>;
  updateUser?: Maybe<User>;
  uploadFile: File;
  validateAgency?: Maybe<ValidateAgencyResponse>;
  validateAgent?: Maybe<ValidateAgentResponse>;
};

export type MutationAcceptClaimsFormTokenInviteArgs = {
  token: Scalars['String'];
};

export type MutationAcceptClientReviewContractInviteArgs = {
  token: Scalars['String'];
};

export type MutationAddAgentPaymentInformationArgs = {
  input: UserPaymentInfoInputs;
};

export type MutationAddAppPermissionArgs = {
  appType: AppType;
  user: Scalars['String'];
};

export type MutationAddClientUserPaymentArgs = {
  contract: Scalars['String'];
  input: UserPaymentInfoInputs;
};

export type MutationAddPropertyArgs = {
  client: Scalars['String'];
  input: AddPropertyInputs;
};

export type MutationApproveAgentArgs = {
  agentId: Scalars['String'];
};

export type MutationApproveLegacyContractArgs = {
  input: ApproveLegacyContractInputs;
};

export type MutationApproveLegacyTransactionArgs = {
  input: ApproveLegacyTransactionInputs;
};

export type MutationAttachClientToQuoteArgs = {
  client: Scalars['String'];
  quote: Scalars['String'];
};

export type MutationAuthorizeArgs = {
  token: Scalars['String'];
};

export type MutationAuthorizeAgencyOnboardingUserArgs = {
  token: Scalars['String'];
};

export type MutationChangePasswordArgs = {
  appType?: AppType;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationClaimSubmissionReviewArgs = {
  appType?: AppType;
  contract: Scalars['String'];
  input: ClaimSubmissionReviewInput;
};

export type MutationClientApplicationVerificationArgs = {
  contract: Scalars['String'];
  input: ClientInfoApplicationInput;
};

export type MutationClientLpcApplicationReviewArgs = {
  appType?: AppType;
  contract: Scalars['String'];
  input: ClientLpcApplicationReviewInput;
};

export type MutationCompleteAgencyOnboardingArgs = {
  input: CompleteAgencyOnboardingInputs;
};

export type MutationConfirmUserArgs = {
  token: Scalars['String'];
  tokenType?: InputMaybe<TokenType>;
};

export type MutationCreateApplicationArgs = {
  client: Scalars['String'];
  quotes: Array<Scalars['String']>;
};

export type MutationCreateClientUserArgs = {
  appType?: InputMaybe<AppType>;
  input: CreateClientUserInputs;
};

export type MutationCreateHhApplicationArgs = {
  client: Scalars['String'];
  input: CreateHhApplicationInputs;
  quotes: Array<Scalars['String']>;
};

export type MutationCreateHhQuoteArgs = {
  client: Scalars['String'];
  input: CreateHybridHomeownersQuoteInputs;
  property: Scalars['String'];
};

export type MutationCreateHhQuoteProspectRequestArgs = {
  clientId: Scalars['String'];
};

export type MutationCreateInsuranceApplicationArgs = {
  client: Scalars['String'];
  quote: Scalars['String'];
};

export type MutationCreateLegacyClientArgs = {
  input: CreateLegacyClientInputs;
};

export type MutationCreateLegacyContractArgs = {
  input: CreateLegacyContractInputs;
};

export type MutationCreateLoginTokenArgs = {
  tokenType?: InputMaybe<TokenType>;
};

export type MutationCreateOrgAdminArgs = {
  inputs: CreateOrgAdminInputs;
  org: Scalars['String'];
};

export type MutationCreateOrganizationArgs = {
  appType: AppType;
  input: CreateOrganizationInputs;
};

export type MutationCreateParametricQuoteArgs = {
  input: CreateParametricQuoteInputs;
};

export type MutationCreatePremiumIndicationRequestArgs = {
  input: PremiumIndicationRequestInputs;
};

export type MutationCreateQuoteArgs = {
  input: CreateQuoteInputs;
  programType: ProgramType;
};

export type MutationDeleteHhQuoteProspectRequestArgs = {
  prospectRequest: Scalars['String'];
};

export type MutationDeletePremiumRequestArgs = {
  deleteType?: InputMaybe<DeleteType>;
  id: Scalars['String'];
};

export type MutationDeletePropertyArgs = {
  property: Scalars['String'];
};

export type MutationDeleteQuoteArgs = {
  deleteType?: InputMaybe<DeleteType>;
  quote: Scalars['String'];
  quoteType: QuoteDiscriminatorType;
};

export type MutationDeleteQuotesArgs = {
  deleteType: DeleteType;
  quoteType: QuoteDiscriminatorType;
  quotes: Array<Scalars['String']>;
};

export type MutationEmailPolicyDocumentArgs = {
  contract: Scalars['String'];
  input: GetFileInputs;
};

export type MutationExecuteContractArgs = {
  inputs: Array<ExecuteContractInputs>;
};

export type MutationExecuteLegacyDerivativeContractArgs = {
  input: ExecuteLegacyDerivativeContractInputs;
};

export type MutationExecuteLegacyRiskContractArgs = {
  inputs: Array<ExecuteContractInputs>;
};

export type MutationForgotPasswordArgs = {
  appType?: AppType;
  email: Scalars['EmailAddress'];
};

export type MutationGetLegacySignedUrlArgs = {
  id: Scalars['String'];
};

export type MutationGetSignedBdxUrlArgs = {
  file: Scalars['String'];
};

export type MutationGetSignedUrlArgs = {
  file: Scalars['String'];
};

export type MutationInitializeLegacyMeteorContractArgs = {
  input: InitializeLegacyMeteorContractInputs;
};

export type MutationInsertPremiumRequestArgs = {
  input: PremiumIndicationRequestInputs;
};

export type MutationInviteAuthorizedContactArgs = {
  inputs: InviteAuthorizedContactInputs;
};

export type MutationInviteClientClaimFormArgs = {
  policyId: Scalars['String'];
};

export type MutationInviteLpcOrgAgentArgs = {
  inputs: InviteOrgAgentInputs;
};

export type MutationInviteOrgAgentArgs = {
  inputs: InviteOrgAgentInputs;
  org?: InputMaybe<Scalars['String']>;
};

export type MutationLoginArgs = {
  appType?: AppType;
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export type MutationPauseApplicationArgs = {
  contract: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationRegisterHubUserArgs = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export type MutationRegisterOrgAgentArgs = {
  inputs: RegisterOrgAgentInputs;
  invitationCode: Scalars['String'];
};

export type MutationRegisterUserArgs = {
  appType?: AppType;
  input: RegisterUserInputs;
  tokenType?: InputMaybe<TokenType>;
};

export type MutationResendConfirmArgs = {
  appType?: AppType;
  tokenType?: InputMaybe<TokenType>;
};

export type MutationResetPasswordArgs = {
  appType?: AppType;
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationReviewProducerRegistrationFormArgs = {
  form: Scalars['String'];
  input: ReviewProducerRegistrationFormInput;
};

export type MutationReviewPropertyArgs = {
  property: Scalars['String'];
};

export type MutationSaveFeedbackArgs = {
  feedback: Scalars['String'];
  rating: Scalars['Int'];
};

export type MutationSaveHhQuotesArgs = {
  client?: InputMaybe<Scalars['String']>;
  quoteIds: Array<Scalars['String']>;
};

export type MutationSaveLpcInsuranceQuoteArgs = {
  client?: InputMaybe<Scalars['String']>;
  quote: Scalars['String'];
};

export type MutationSaveLtcInsuranceQuoteArgs = {
  client?: InputMaybe<Scalars['String']>;
  quote: Scalars['String'];
};

export type MutationSaveLossReportArgs = {
  input: SaveLossReportInput;
};

export type MutationSaveProducerRegistrationArgs = {
  input: SaveProducerRegistrationInputs;
};

export type MutationSaveQuoteArgs = {
  client?: InputMaybe<Scalars['String']>;
  quote: Scalars['String'];
};

export type MutationSendAggregatedPayoutReportArgs = {
  inputs: SendAggregatedPayoutReportInputs;
};

export type MutationSendBespokeDataAlertArgs = {
  inputs: SendBespokeDataAlertInputs;
};

export type MutationSendDerivativesOverdueTransactionAlertArgs = {
  inputs: Array<SendDerivativesOverdueTransactionAlertInputs>;
  type: TransactionContext;
};

export type MutationSendFinalDataCorrectionAlertArgs = {
  inputs: SendFinalDataCorrectionAlertInputs;
};

export type MutationSendPrelimDataCorrectionAlertArgs = {
  inputs: SendPrelimDataCorrectionAlertInputs;
};

export type MutationSendUpcomingCalculationDateReportArgs = {
  relativeDays: Scalars['Int'];
};

export type MutationSetEvaluationArgs = {
  contract: Scalars['String'];
  evaluationData: EvaluationData;
  payout: Scalars['Float'];
  riskContract: Scalars['String'];
};

export type MutationSetLegacyEvaluationArgs = {
  input: SetLegacyEvaluationInputs;
};

export type MutationShareLtcInsuranceQuoteArgs = {
  emails: Array<Scalars['String']>;
  quoteId: Scalars['String'];
};

export type MutationSharePrecipInsuranceQuoteArgs = {
  emails: Array<Scalars['String']>;
  quoteId: Scalars['String'];
};

export type MutationSharePremiumRequestArgs = {
  emails: Array<Scalars['EmailAddress']>;
  id: Scalars['String'];
};

export type MutationSubmitLpcInsuranceApplicationArgs = {
  contract: Scalars['String'];
  input: SubmitLpcInsuranceApplicationInput;
};

export type MutationSubmitPremiumRequestArgs = {
  id: Scalars['String'];
  input: PremiumRequestSubmitInputs;
};

export type MutationSubmitProducerRegistrationArgs = {
  input: SignatureAcknowledgementInput;
};

export type MutationSubmitReviewedLpcApplicationByClientArgs = {
  contract: Scalars['String'];
  input: ReviewApplicationInputs;
};

export type MutationSubmitTradeExecutionRequestArgs = {
  input: Hjord_SubmitTradeExecutionRequest;
};

export type MutationUnderwritingCheckHhArgs = {
  client: Scalars['String'];
  input: UnderwritingCheckHhInputs;
  property: Scalars['String'];
};

export type MutationUnpauseApplicationArgs = {
  contract: Scalars['String'];
  input: UnpauseApplicationInputs;
};

export type MutationUpdateClientEmailArgs = {
  client: Scalars['String'];
  email: Scalars['EmailAddress'];
};

export type MutationUpdateClientUserArgs = {
  client: Scalars['String'];
  input: UpdateUserInputs;
};

export type MutationUpdateHhQuoteProspectRequestArgs = {
  input: UpdateProspectRequestInputs;
  prospectRequest: Scalars['String'];
};

export type MutationUpdateLegacyContractArgs = {
  id: Scalars['String'];
  input: UpdateLegacyContractInputs;
};

export type MutationUpdateLegacyTransactionArgs = {
  id: Scalars['String'];
  input: UpdateLegacyTransactionInputs;
};

export type MutationUpdateLpcInsuranceApplicationArgs = {
  contract: Scalars['String'];
  input: UpdateLpcInsuranceApplicationInputs;
};

export type MutationUpdateOrganizationArgs = {
  input: CreateOrganizationInputs;
  organization: Scalars['String'];
};

export type MutationUpdateOrganizationContactArgs = {
  contact: Scalars['String'];
  input: UpdateOrganizationContactInputs;
  organization: Scalars['String'];
};

export type MutationUpdateOrganizationLocationAddressArgs = {
  input: OrgLocationInputs;
  location: Scalars['String'];
  organization: Scalars['String'];
};

export type MutationUpdatePremiumRequestArgs = {
  id: Scalars['String'];
  input: PremiumRequestUpdateInputs;
};

export type MutationUpdatePropertyArgs = {
  input: UpdatePropertyInputs;
  property: Scalars['String'];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInputs;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  input: FileStorageInputs;
};

export type MutationValidateAgencyArgs = {
  input: ValidateAgencyInputs;
};

export type MutationValidateAgentArgs = {
  input: ValidateAgentInputs;
};

export type OfacDetails = {
  __typename?: 'OfacDetails';
  approved?: Maybe<Scalars['Boolean']>;
  checkedAt?: Maybe<Scalars['DateTime']>;
};

export enum OptionType {
  Call = 'CALL',
  Put = 'PUT',
}

export type OrgContact = {
  __typename?: 'OrgContact';
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Role>;
};

export type OrgContactInputs = {
  _id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Role>;
};

export type OrgDocumentInput = {
  name: Scalars['String'];
  type: DocumentType;
  upload: Scalars['String'];
};

export type OrgLicenseInput = {
  upload: Scalars['String'];
  userId: Scalars['String'];
};

export type OrgLicensedProducer = {
  __typename?: 'OrgLicensedProducer';
  _id?: Maybe<Scalars['ID']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrgLicensedProducerInputs = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type OrgLocation = {
  __typename?: 'OrgLocation';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<Address>;
  contacts?: Maybe<Array<Maybe<OrgContact>>>;
  primaryLocation?: Maybe<Scalars['Boolean']>;
  type?: Maybe<OrgLocationType>;
};

export type OrgLocationInputs = {
  _id?: InputMaybe<Scalars['ID']>;
  address: AddressInputs;
  contacts?: InputMaybe<Array<InputMaybe<OrgContactInputs>>>;
  primaryLocation?: InputMaybe<Scalars['Boolean']>;
  type: OrgLocationType;
};

export enum OrgLocationType {
  Mailing = 'Mailing',
  Physical = 'Physical',
}

export type OrgPermission = {
  __typename?: 'OrgPermission';
  canBind?: Maybe<Scalars['Boolean']>;
};

export type OrgPremiumVolume = {
  __typename?: 'OrgPremiumVolume';
  _id?: Maybe<Scalars['ID']>;
  commercialPCPersonalLines?: Maybe<UnitVectorFloat>;
  personalPCPersonalLines?: Maybe<UnitVectorFloat>;
  recordedAt?: Maybe<Scalars['DateTime']>;
  totalPremium?: Maybe<UnitVectorFloat>;
  year?: Maybe<Scalars['Int']>;
};

export type OrgSignatureDetails = {
  __typename?: 'OrgSignatureDetails';
  signature?: Maybe<Scalars['String']>;
  signingDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export enum OrgTeamStatus {
  Active = 'Active',
  Disabled = 'Disabled',
  Error = 'Error',
  Invited = 'Invited',
}

export type Organization = {
  __typename?: 'Organization';
  _id?: Maybe<Scalars['ID']>;
  affiliatedTradeAssociations?: Maybe<Array<Maybe<Scalars['String']>>>;
  application?: Maybe<Form>;
  backgroundDetails?: Maybe<BackgroundDetails>;
  commissionRate?: Maybe<Scalars['Float']>;
  companyHistory?: Maybe<CompanyHistory>;
  coverAllLocations?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<Document>>;
  fein?: Maybe<Scalars['String']>;
  hasSurplusLinesLicense?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<History>>>;
  isOnboarded?: Maybe<Scalars['Boolean']>;
  licensedProducers?: Maybe<Array<Maybe<OrgLicensedProducer>>>;
  locations?: Maybe<Array<Maybe<OrgLocation>>>;
  name?: Maybe<Scalars['String']>;
  npn?: Maybe<Scalars['String']>;
  ofac?: Maybe<OfacDetails>;
  onboardingApplicationSubmitted?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<OrgPermission>>>;
  prettyOrgId?: Maybe<Scalars['String']>;
  producerType?: Maybe<OrganizationProducerType>;
  questionnaireResponses?: Maybe<QuestionnaireResponses>;
  signatureDetails?: Maybe<OrgSignatureDetails>;
  team?: Maybe<Array<Team>>;
  tradeNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Scalars['String']>;
  yearsInBusiness?: Maybe<Scalars['Int']>;
};

export enum OrganizationProducerType {
  Network = 'Network',
  Retailer = 'Retailer',
  Wholesaler = 'Wholesaler',
}

export type PaginatedContractPayload = {
  __typename?: 'PaginatedContractPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Contract>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedFormPayload = {
  __typename?: 'PaginatedFormPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Form>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedOrganizationPayload = {
  __typename?: 'PaginatedOrganizationPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Organization>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedPremiumRequestPayload = {
  __typename?: 'PaginatedPremiumRequestPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<PremiumRequest>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedPropertyPayload = {
  __typename?: 'PaginatedPropertyPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Property>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedQuotePayload = {
  __typename?: 'PaginatedQuotePayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Quote>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedS3FilePayload = {
  __typename?: 'PaginatedS3FilePayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<S3File>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['String']>;
};

export type PaginatedTransactionPayload = {
  __typename?: 'PaginatedTransactionPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Transaction>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedUploadPayload = {
  __typename?: 'PaginatedUploadPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<File>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginatedUserPayload = {
  __typename?: 'PaginatedUserPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<User>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PaginationInputs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type ParametricContract = Contract & {
  __typename?: 'ParametricContract';
  _id?: Maybe<Scalars['ID']>;
  application?: Maybe<Application>;
  capitalProvider?: Maybe<Scalars['String']>;
  claim?: Maybe<Form>;
  clientSignature?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerCounterparty?: Maybe<User>;
  documents?: Maybe<Array<Maybe<File>>>;
  evaluationData?: Maybe<IndexData>;
  hasFinalTaxes?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<History>>>;
  lifecycleStage?: Maybe<Scalars['String']>;
  pausedAt?: Maybe<Scalars['DateTime']>;
  pausedBy?: Maybe<User>;
  pausedReason?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['String']>;
  prices?: Maybe<Prices>;
  quotes?: Maybe<Array<Maybe<ParametricQuote>>>;
  reviewedBy?: Maybe<User>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ParametricCoverageStructure = {
  __typename?: 'ParametricCoverageStructure';
  dataset?: Maybe<Dataset>;
  endDate?: Maybe<Scalars['DateTime']>;
  exhaust?: Maybe<UnitVectorFloat>;
  lcRequired?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<UnitVectorInt>;
  location?: Maybe<GeoJson>;
  programName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  strike?: Maybe<UnitVectorFloat>;
};

export type ParametricPrices = {
  __typename?: 'ParametricPrices';
  agentCommission?: Maybe<UnitVectorRateFloat>;
  agentCommissionPercent?: Maybe<UnitVectorFloat>;
  arbolCommission?: Maybe<UnitVectorRateFloat>;
  arbolCommissionPercent?: Maybe<UnitVectorFloat>;
  customerPremium?: Maybe<UnitVectorFloat>;
  dClimateCommission?: Maybe<UnitVectorRateFloat>;
  dClimateCommissionPercent?: Maybe<UnitVectorFloat>;
  estimatedGrossPremium?: Maybe<UnitVectorFloat>;
  estimatedTotalTaxes?: Maybe<UnitVectorFloat>;
  estimatedTotalTaxesNoStamping?: Maybe<UnitVectorFloat>;
  expectedLoss?: Maybe<Scalars['Float']>;
  expectedPayout?: Maybe<UnitVectorFloat>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  policyFee?: Maybe<UnitVectorFloat>;
  riskCapitalCommission?: Maybe<UnitVectorRateFloat>;
  riskCapitalCommissionPercent?: Maybe<UnitVectorFloat>;
  stampingFee?: Maybe<UnitVectorFloat>;
};

export type ParametricQuote = Quote & {
  __typename?: 'ParametricQuote';
  _id?: Maybe<Scalars['ID']>;
  breakEven?: Maybe<UnitVectorFloat>;
  contract?: Maybe<ParametricContract>;
  coverageStructure?: Maybe<ParametricCoverageStructure>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCounterparty?: Maybe<User>;
  frozenAt?: Maybe<Scalars['DateTime']>;
  history?: Maybe<Array<Maybe<History>>>;
  limitReachedWarning?: Maybe<Scalars['Boolean']>;
  perUnitLoss?: Maybe<UnitVectorFloat>;
  prices?: Maybe<ParametricPrices>;
  requestId?: Maybe<Scalars['String']>;
  salesAgent?: Maybe<User>;
  salesPlatform?: Maybe<SalesPlatform>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum PaymentMethod {
  Ach = 'ACH',
  ECheck = 'eCheck',
}

export type PremiumIndicationRequestInputs = {
  average?: InputMaybe<Scalars['Float']>;
  dataProvider?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTime'];
  lastModifiedById?: InputMaybe<Scalars['String']>;
  limit: Scalars['Float'];
  locations?: InputMaybe<Array<PremiumRequestLocationInputs>>;
  minMax?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfOptions?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<Array<PremiumRequestOptionInputs>>;
  programType: ProgramType;
  source?: InputMaybe<PremiumRequestSource>;
  start: Scalars['DateTime'];
  tradeDeadline?: InputMaybe<Scalars['DateTime']>;
  unitType?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type PremiumRequest = {
  __typename?: 'PremiumRequest';
  _id?: Maybe<Scalars['ID']>;
  average?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dataProvider?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<User>;
  lastModifiedById?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  locations?: Maybe<Array<PremiumRequestLocation>>;
  minMax?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  numberOfOptions?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<PremiumRequestOption>>;
  programType?: Maybe<ProgramType>;
  source?: Maybe<PremiumRequestSource>;
  start?: Maybe<Scalars['DateTime']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  tradeDeadline?: Maybe<Scalars['DateTime']>;
  unitType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type PremiumRequestLocation = {
  __typename?: 'PremiumRequestLocation';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PremiumRequestLocationInputs = {
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type PremiumRequestOption = {
  __typename?: 'PremiumRequestOption';
  _id?: Maybe<Scalars['ID']>;
  commission?: Maybe<Scalars['Float']>;
  exhaust?: Maybe<Scalars['Float']>;
  expectedLoss?: Maybe<Scalars['Float']>;
  expectedLossRate?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  loadedPremium?: Maybe<Scalars['Float']>;
  perUnitLoss?: Maybe<Scalars['Float']>;
  premiumRecovery?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  strike?: Maybe<Scalars['Float']>;
  unloadedPremium?: Maybe<Scalars['Float']>;
};

export type PremiumRequestOptionInputs = {
  commission?: InputMaybe<Scalars['Float']>;
  exhaust?: InputMaybe<Scalars['Float']>;
  expectedLoss?: InputMaybe<Scalars['Float']>;
  expectedLossRate?: InputMaybe<Scalars['Float']>;
  level?: InputMaybe<Scalars['Float']>;
  loadedPremium?: InputMaybe<Scalars['Float']>;
  perUnitLoss?: InputMaybe<Scalars['Float']>;
  premiumRecovery?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['Float']>;
  strike?: InputMaybe<Scalars['Float']>;
  unloadedPremium?: InputMaybe<Scalars['Float']>;
};

export enum PremiumRequestSource {
  Pie = 'PIE',
  Platform = 'PLATFORM',
}

export type PremiumRequestSubmitInputs = {
  arbolId: Scalars['String'];
  commission: CommissionInputs;
  optionId: Scalars['String'];
};

export type PremiumRequestUpdateInputs = {
  average?: InputMaybe<Scalars['Float']>;
  dataProvider?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  lastModifiedById?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  locations?: InputMaybe<Array<PremiumRequestLocationInputs>>;
  minMax?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfOptions?: InputMaybe<Scalars['Int']>;
  options?: InputMaybe<Array<PremiumRequestOptionInputs>>;
  programType?: InputMaybe<ProgramType>;
  source?: InputMaybe<PremiumRequestSource>;
  start?: InputMaybe<Scalars['DateTime']>;
  tradeDeadline?: InputMaybe<Scalars['DateTime']>;
  unitType?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type Prices = {
  __typename?: 'Prices';
  grossPremium?: Maybe<UnitVectorFloat>;
  netPremium?: Maybe<UnitVectorFloat>;
  policyFee?: Maybe<UnitVectorFloat>;
  stampingFee?: Maybe<UnitVectorFloat>;
  totalTaxesNoStamping?: Maybe<UnitVectorFloat>;
};

export type Producer = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  producerType?: InputMaybe<Role>;
  stateLicenseNumber?: InputMaybe<Scalars['String']>;
  stateOfLicense?: InputMaybe<Scalars['String']>;
};

export enum ProductType {
  ExcessPrecipitation = 'EXCESS_PRECIPITATION',
  ExcessSnowfall = 'EXCESS_SNOWFALL',
  ExcessSoilMoisture = 'EXCESS_SOIL_MOISTURE',
  HighTemperature = 'HIGH_TEMPERATURE',
  HighWind = 'HIGH_WIND',
  LowPrecipitation = 'LOW_PRECIPITATION',
  LowSnowfall = 'LOW_SNOWFALL',
  LowSolarIrradiance = 'LOW_SOLAR_IRRADIANCE',
  LowTemperature = 'LOW_TEMPERATURE',
  LowWind = 'LOW_WIND',
  NamedStorm = 'NAMED_STORM',
}

export enum ProgramType {
  CustomExcessRain = 'CUSTOM_EXCESS_RAIN',
  CustomLowRain = 'CUSTOM_LOW_RAIN',
  EsmInsurance = 'ESM_INSURANCE',
  ExcessPrecipitation = 'EXCESS_PRECIPITATION',
  ExcessSnowfall = 'EXCESS_SNOWFALL',
  ExcessSoilMoisture = 'EXCESS_SOIL_MOISTURE',
  Grp = 'GRP',
  GrpInsurance = 'GRP_INSURANCE',
  HighTemperature = 'HIGH_TEMPERATURE',
  HighWind = 'HIGH_WIND',
  HpcInsurance = 'HPC_INSURANCE',
  HscInsurance = 'HSC_INSURANCE',
  HtcInsurance = 'HTC_INSURANCE',
  HwcInsurance = 'HWC_INSURANCE',
  LowPrecipitation = 'LOW_PRECIPITATION',
  LowSnowfall = 'LOW_SNOWFALL',
  LowSolarIrradiance = 'LOW_SOLAR_IRRADIANCE',
  LowTemperature = 'LOW_TEMPERATURE',
  LowWind = 'LOW_WIND',
  Lpc = 'LPC',
  LpcInsurance = 'LPC_INSURANCE',
  LscInsurance = 'LSC_INSURANCE',
  LsiInsurance = 'LSI_INSURANCE',
  LtcInsurance = 'LTC_INSURANCE',
  LwcInsurance = 'LWC_INSURANCE',
  NamedStorm = 'NAMED_STORM',
  NscInsurance = 'NSC_INSURANCE',
  Xsr = 'XSR',
}

export enum ProgramTypeIndexData {
  Htc = 'HTC',
  Ltc = 'LTC',
}

export type Properties = {
  __typename?: 'Properties';
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type Property = {
  __typename?: 'Property';
  _id?: Maybe<Scalars['ID']>;
  address?: Maybe<PropertyAddress>;
  client?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']>;
  specs?: Maybe<ProperySpecs>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyAddress = {
  __typename?: 'PropertyAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type PropertyQuoteDetails = {
  __typename?: 'PropertyQuoteDetails';
  address?: Maybe<Address>;
  propertyRef?: Maybe<Scalars['ID']>;
  specs?: Maybe<PropertyQuoteSpecs>;
};

export type PropertyQuoteSpecs = {
  __typename?: 'PropertyQuoteSpecs';
  burglarAlarm?: Maybe<Scalars['Boolean']>;
  construction?: Maybe<Scalars['String']>;
  fireAlarm?: Maybe<Scalars['Boolean']>;
  roofMaterial?: Maybe<Scalars['String']>;
  roofYear?: Maybe<Scalars['Int']>;
  seasonalProperty?: Maybe<Scalars['Boolean']>;
  sprinklerSystem?: Maybe<Scalars['Boolean']>;
  yearBuilt?: Maybe<Scalars['Int']>;
};

export type ProperySpecs = {
  __typename?: 'ProperySpecs';
  bcegCode?: Maybe<Scalars['Int']>;
  burglarAlarm?: Maybe<Scalars['Boolean']>;
  construction?: Maybe<ConstructionType>;
  constructionYear?: Maybe<Scalars['Int']>;
  county?: Maybe<Scalars['String']>;
  coverageLimitMin?: Maybe<Scalars['Int']>;
  distanceGreaterThanOneMileFromCoast?: Maybe<Scalars['Boolean']>;
  eligible?: Maybe<Scalars['Boolean']>;
  fireAlarm?: Maybe<Scalars['Boolean']>;
  insuranceScore?: Maybe<Scalars['Int']>;
  priorClaims?: Maybe<Scalars['Int']>;
  propertyAge?: Maybe<Scalars['Int']>;
  protectionClass?: Maybe<Scalars['Int']>;
  roofMaterial?: Maybe<RoofMaterialType>;
  roofYear?: Maybe<Scalars['Int']>;
  seasonalOccupancy?: Maybe<Scalars['Boolean']>;
  seasonalProperty?: Maybe<Scalars['Boolean']>;
  sprinklerSystem?: Maybe<Scalars['Boolean']>;
  territory?: Maybe<Scalars['String']>;
  territoryGroup?: Maybe<Scalars['Int']>;
  woodShingledRoof?: Maybe<Scalars['Boolean']>;
  yearBuilt?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['Int']>;
};

export type ProspectRequest =
  | HhOrganizationProspectRequest
  | HhQuoteProspectRequest;

export enum ProspectRequestDiscriminatorType {
  Organization = 'ORGANIZATION',
  Quote = 'QUOTE',
}

export enum ProspectRequestUnderWritingCheckOptionName {
  Businessproperty = 'BUSINESSPROPERTY',
  Commercialproperty = 'COMMERCIALPROPERTY',
  Condemnedproperty = 'CONDEMNEDPROPERTY',
  Divingboard = 'DIVINGBOARD',
  Farmorranch = 'FARMORRANCH',
  Fourormoremortages = 'FOURORMOREMORTAGES',
  Fraternityorsoronity = 'FRATERNITYORSORONITY',
  Homemadeorrebuilt = 'HOMEMADEORREBUILT',
  Misrepresentationarsonorfraud = 'MISREPRESENTATIONARSONORFRAUD',
  Mobileorprebabricated = 'MOBILEORPREBABRICATED',
  Nonhabitational = 'NONHABITATIONAL',
  Underconstruction = 'UNDERCONSTRUCTION',
}

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  getAgentCommissions?: Maybe<PaginatedTransactionPayload>;
  getAgentStats?: Maybe<AgentStatsPayload>;
  getBordereauReport?: Maybe<Array<Maybe<BordereauReportRecord>>>;
  getBordereauReportFiles?: Maybe<PaginatedS3FilePayload>;
  getCHIRPRainfallDataByLocations: Array<RainfallDataByLocation>;
  getContract?: Maybe<Contract>;
  getContractByPolicyId?: Maybe<Contract>;
  getContractByQuoteId?: Maybe<Contract>;
  getContracts?: Maybe<PaginatedContractPayload>;
  getDatasetMetaDataV4?: Maybe<Scalars['JSON']>;
  getDerivativesOverdueTransactions?: Maybe<
    Array<Maybe<DerivativesOverdueTransaction>>
  >;
  getEvaluation?: Maybe<Evaluation>;
  getForm?: Maybe<Form>;
  /** @deprecated Use getDatasetMetaDataV4 instead */
  getGatewayMetaData?: Maybe<Scalars['JSON']>;
  getHHQuoteProspectRequest?: Maybe<HhQuoteProspectRequest>;
  getIndexData?: Maybe<GetIndexDataResponse>;
  getIndexDataV2?: Maybe<GetIndexDataResponse>;
  getLPCDates: Array<RiskPeriodDateBlock>;
  getLTCDates: Array<RiskPeriodDateBlock>;
  getLegacyBespokeDataEvaluationContracts?: Maybe<
    Array<Maybe<LegacyEvaluationContract>>
  >;
  getLegacyContractById?: Maybe<Scalars['JSON']>;
  getLegacyContractConfirms?: Maybe<Scalars['JSON']>;
  getLegacyContracts?: Maybe<Scalars['JSON']>;
  getLegacyDataCorrectionContracts?: Maybe<
    Array<Maybe<LegacyDataCorrectionContract>>
  >;
  getLegacyDraftContracts?: Maybe<Scalars['JSON']>;
  getLegacyEvaluatedContracts?: Maybe<Array<Maybe<LegacyEvaluatedContract>>>;
  getLegacyEvaluation?: Maybe<Scalars['JSON']>;
  getLegacyEvaluationContracts?: Maybe<Array<Maybe<LegacyEvaluationContract>>>;
  getLegacyFxRate?: Maybe<Scalars['JSON']>;
  getLegacyPaidContracts?: Maybe<Scalars['JSON']>;
  getLegacyUnpaidContracts?: Maybe<Scalars['JSON']>;
  getLegacyUnpaidContractsStats?: Maybe<Scalars['JSON']>;
  getLegacyUsers?: Maybe<Scalars['JSON']>;
  getLossReportForms?: Maybe<PaginatedFormPayload>;
  getOrganization?: Maybe<Organization>;
  getOrganizationClients?: Maybe<PaginatedUserPayload>;
  getOrganizationTeamMembers?: Maybe<PaginatedUserPayload>;
  getOrganizations?: Maybe<PaginatedOrganizationPayload>;
  getPremiumRequest?: Maybe<PremiumRequest>;
  getPremiumRequests?: Maybe<PaginatedPremiumRequestPayload>;
  getProducerRegistrationForms?: Maybe<PaginatedFormPayload>;
  getProperties?: Maybe<PaginatedPropertyPayload>;
  getProperty?: Maybe<Property>;
  getQuote?: Maybe<Quote>;
  getQuotes?: Maybe<PaginatedQuotePayload>;
  getRainfallDataByLocations: Array<RainfallDataByLocation>;
  getRedBudPayment?: Maybe<RedBudPayment>;
  getRiskQuote?: Maybe<RiskQuote>;
  getUploads?: Maybe<PaginatedUploadPayload>;
  getUser?: Maybe<User>;
  getUserClients?: Maybe<PaginatedUserPayload>;
  getUserDocuments?: Maybe<Array<Maybe<File>>>;
  getUsers?: Maybe<PaginatedUserPayload>;
};

export type QueryGetAgentCommissionsArgs = {
  input?: InputMaybe<PaginationInputs>;
};

export type QueryGetBordereauReportArgs = {
  startDate: Scalars['String'];
};

export type QueryGetBordereauReportFilesArgs = {
  input?: InputMaybe<PaginationInputs>;
  offset?: InputMaybe<Scalars['String']>;
};

export type QueryGetChirpRainfallDataByLocationsArgs = {
  input: Array<RainfallDataByLocationInput>;
};

export type QueryGetContractArgs = {
  id: Scalars['String'];
};

export type QueryGetContractByPolicyIdArgs = {
  policyId: Scalars['String'];
};

export type QueryGetContractByQuoteIdArgs = {
  quoteId: Scalars['String'];
};

export type QueryGetContractsArgs = {
  applicationStatuses?: InputMaybe<Array<InputMaybe<ApplicationStatus>>>;
  input?: InputMaybe<PaginationInputs>;
  lifecycleStages?: InputMaybe<Array<InputMaybe<LifecycleStage>>>;
  type: ContractDiscriminatorType;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetDatasetMetaDataV4Args = {
  dataset: Scalars['String'];
};

export type QueryGetDerivativesOverdueTransactionsArgs = {
  type: TransactionContext;
};

export type QueryGetEvaluationArgs = {
  contract: Scalars['String'];
  riskContract: Scalars['String'];
};

export type QueryGetFormArgs = {
  formType: FormType;
  id?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['String']>;
};

export type QueryGetGatewayMetaDataArgs = {
  dataset: Scalars['String'];
};

export type QueryGetHhQuoteProspectRequestArgs = {
  id: Scalars['String'];
};

export type QueryGetIndexDataArgs = {
  input: GetIndexDataInput;
  type: ProgramTypeIndexData;
};

export type QueryGetIndexDataV2Args = {
  input: GetIndexDataInputV2;
  type: ProgramTypeIndexData;
};

export type QueryGetLegacyBespokeDataEvaluationContractsArgs = {
  dataset?: InputMaybe<Scalars['String']>;
  type: LegacySourceType;
};

export type QueryGetLegacyContractByIdArgs = {
  id: Scalars['String'];
};

export type QueryGetLegacyContractConfirmsArgs = {
  id: Scalars['String'];
};

export type QueryGetLegacyContractsArgs = {
  input?: InputMaybe<LegacyContractPaginationInputs>;
};

export type QueryGetLegacyDraftContractsArgs = {
  input?: InputMaybe<PaginationInputs>;
};

export type QueryGetLegacyEvaluatedContractsArgs = {
  relativeDays: Scalars['Int'];
};

export type QueryGetLegacyEvaluationArgs = {
  input: LegacyEvaluationInputs;
};

export type QueryGetLegacyEvaluationContractsArgs = {
  type: LegacySourceType;
};

export type QueryGetLegacyFxRateArgs = {
  currency: Scalars['String'];
  date: Scalars['String'];
};

export type QueryGetLegacyPaidContractsArgs = {
  input?: InputMaybe<PaginationInputs>;
};

export type QueryGetLegacyUnpaidContractsArgs = {
  input?: InputMaybe<PaginationInputs>;
  type: LegacyUnpaidContractType;
};

export type QueryGetLegacyUsersArgs = {
  input?: InputMaybe<PaginationInputs>;
  search: Scalars['String'];
  type?: InputMaybe<LegacyUserType>;
};

export type QueryGetLossReportFormsArgs = {
  input?: InputMaybe<PaginationInputs>;
  status?: InputMaybe<Array<InputMaybe<FormStatus>>>;
};

export type QueryGetOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryGetOrganizationClientsArgs = {
  input?: InputMaybe<PaginationInputs>;
  orgId: Scalars['String'];
};

export type QueryGetOrganizationTeamMembersArgs = {
  input?: InputMaybe<PaginationInputs>;
  orgId: Scalars['String'];
};

export type QueryGetOrganizationsArgs = {
  filterOnboarded?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<PaginationInputs>;
};

export type QueryGetPremiumRequestArgs = {
  id: Scalars['String'];
};

export type QueryGetPremiumRequestsArgs = {
  filterSoftDelete?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<PaginationInputs>;
};

export type QueryGetProducerRegistrationFormsArgs = {
  input?: InputMaybe<PaginationInputs>;
  status?: InputMaybe<Array<InputMaybe<FormStatus>>>;
};

export type QueryGetPropertiesArgs = {
  input?: InputMaybe<PaginationInputs>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetPropertyArgs = {
  property: Scalars['String'];
};

export type QueryGetQuoteArgs = {
  id: Scalars['String'];
};

export type QueryGetQuotesArgs = {
  filterFrozen?: InputMaybe<Scalars['Boolean']>;
  filterSaved?: InputMaybe<Scalars['Boolean']>;
  filterSoftDelete?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<PaginationInputs>;
  type: QuoteDiscriminatorType;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetRainfallDataByLocationsArgs = {
  asOf?: InputMaybe<Scalars['DateTime']>;
  input: Array<RainfallDataByLocationInput>;
};

export type QueryGetRedBudPaymentArgs = {
  paymentId: Scalars['String'];
};

export type QueryGetRiskQuoteArgs = {
  id: Scalars['String'];
};

export type QueryGetUploadsArgs = {
  docIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<PaginationInputs>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGetUserArgs = {
  id: Scalars['String'];
};

export type QueryGetUserClientsArgs = {
  input?: InputMaybe<PaginationInputs>;
};

export type QueryGetUsersArgs = {
  input?: InputMaybe<PaginationInputs>;
  role?: InputMaybe<Role>;
};

export type QuestionnaireResponses = {
  __typename?: 'QuestionnaireResponses';
  allowInspectionOfRecordsRelatedToWholesalerProducts?: Maybe<
    Scalars['Boolean']
  >;
  hasDataSecurityIncidentsLast2Years?: Maybe<Scalars['Boolean']>;
  hasDisasterRecoveryPlan?: Maybe<Scalars['Boolean']>;
  hasOfacScreenings?: Maybe<Scalars['Boolean']>;
  hasOffsiteDataBackup?: Maybe<Scalars['Boolean']>;
  hasWrittenAgreementWithEachRetailProducer?: Maybe<Scalars['Boolean']>;
  permitPaymentOfPremiumBeforeBinding?: Maybe<Scalars['Boolean']>;
  prohibitAdvertisingWholesalerProductsWithoutApproval?: Maybe<
    Scalars['Boolean']
  >;
  prohibitBindingInsuranceWithoutApproval?: Maybe<Scalars['Boolean']>;
  requireClaimsForwardedWholesaler?: Maybe<Scalars['Boolean']>;
  requireConfidentialTreatmentOfProprietaryOrTradeSecretInformationProvidedByWholesaler?: Maybe<
    Scalars['Boolean']
  >;
  requireImplementationOfDataSecurityMeasures?: Maybe<Scalars['Boolean']>;
  requireNoticeToWholesalerOfDataSecurityIncident?: Maybe<Scalars['Boolean']>;
  requireRetentionOfRecordsRelatedToWholesalerProducts?: Maybe<
    Scalars['Boolean']
  >;
};

export type Quote = {
  _id?: Maybe<Scalars['ID']>;
  contract?: Maybe<Contract>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customerCounterparty?: Maybe<User>;
  history?: Maybe<Array<Maybe<History>>>;
  salesAgent?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum QuoteDiscriminatorType {
  Hybridhomeowners = 'HYBRIDHOMEOWNERS',
  Parametric = 'PARAMETRIC',
}

export type RainfallDataByLocation = {
  __typename?: 'RainfallDataByLocation';
  city: Scalars['String'];
  country: Scalars['String'];
  date: Scalars['String'];
  location: Array<Scalars['Float']>;
  rainfall: Scalars['Float'];
  unit: Scalars['String'];
};

export type RainfallDataByLocationInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  location: Array<Scalars['Float']>;
  startDate: Scalars['DateTime'];
};

export type RedBudAmountAndCurrency = {
  __typename?: 'RedBudAmountAndCurrency';
  amount?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

export type RedBudBankAccount = {
  __typename?: 'RedBudBankAccount';
  account_number?: Maybe<Scalars['String']>;
  account_number_type?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  routing_number?: Maybe<Scalars['String']>;
  routing_number_type?: Maybe<Scalars['String']>;
};

export type RedBudCreditor = {
  __typename?: 'RedBudCreditor';
  bank_account?: Maybe<RedBudBankAccount>;
  name?: Maybe<Scalars['String']>;
};

export type RedBudDebtor = {
  __typename?: 'RedBudDebtor';
  ach_company_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RedBudPayment = {
  __typename?: 'RedBudPayment';
  payee_name?: Maybe<Scalars['String']>;
  payment_amount?: Maybe<Scalars['Float']>;
  payment_date?: Maybe<Scalars['String']>;
  payment_status?: Maybe<Scalars['String']>;
  trace_number?: Maybe<Scalars['String']>;
};

export type RegisterOrgAgentInputs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterUserInputs = {
  activationCode?: InputMaybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  orgShortCode?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone: Scalars['String'];
  recaptcha: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  stateLicenseNumber: Scalars['String'];
  zipcode?: InputMaybe<Scalars['String']>;
};

export type ReviewApplicationInputs = {
  agreeElectronicClient: Scalars['Boolean'];
  agreeTerrorismDisclosure: Scalars['Boolean'];
  d1SignName?: InputMaybe<Scalars['String']>;
  signNameCustomer: Scalars['String'];
};

export type ReviewApplicationResponse = {
  __typename?: 'ReviewApplicationResponse';
  failedChecks?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReviewProducerRegistrationFormInput = {
  isApproved: Scalars['Boolean'];
  reviewBy: Scalars['String'];
  reviewDate: Scalars['String'];
  reviewDescription?: InputMaybe<Scalars['String']>;
};

export type ReviewProducerRegistrationFormResponse = {
  __typename?: 'ReviewProducerRegistrationFormResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReviewPropertyResponse = {
  __typename?: 'ReviewPropertyResponse';
  failedQuestions?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReviewUnderwritingCheck = {
  __typename?: 'ReviewUnderwritingCheck';
  failureItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RiskExecutionResult = {
  __typename?: 'RiskExecutionResult';
  contractId?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['String']>;
  riskContractId?: Maybe<Scalars['String']>;
  riskQuoteId?: Maybe<Scalars['String']>;
};

export type RiskPeriodDateBlock = {
  __typename?: 'RiskPeriodDateBlock';
  deadlineDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type RiskQuote = {
  __typename?: 'RiskQuote';
  collection?: Maybe<Scalars['String']>;
  dataset?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  riskQuoteId?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'admin',
  Agent = 'agent',
  Client = 'client',
  Drehubuser = 'drehubuser',
  Internal = 'internal',
  PrimaryAgent = 'primaryAgent',
  Service = 'service',
  SuperAdmin = 'superAdmin',
}

export enum S3Bucket {
  BdxFiles = 'BDX_FILES',
  GeneralFiles = 'GENERAL_FILES',
}

export type S3File = {
  __typename?: 'S3File';
  _id?: Maybe<Scalars['ID']>;
  bucketName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SalesPlatform = {
  __typename?: 'SalesPlatform';
  breakEven?: Maybe<UnitVectorFloat>;
  index?: Maybe<UnitHistory>;
  maxSinglePolicyLimitWarning?: Maybe<Scalars['Boolean']>;
  payouts?: Maybe<UnitHistory>;
  perUnitLoss?: Maybe<UnitVectorFloat>;
  relativeStrike?: Maybe<Scalars['Float']>;
};

export type SaveLossReportInput = {
  address1?: InputMaybe<Scalars['String']>;
  agreeElectronicNamedInsured?: InputMaybe<Scalars['Boolean']>;
  basisDescription?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientSignature?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  notificationDate?: InputMaybe<Scalars['DateTime']>;
  payoutMethod?: InputMaybe<PaymentMethod>;
  policyNumber?: InputMaybe<Scalars['String']>;
  reviewDate?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type SaveProducerRegistrationInputs = {
  _id?: InputMaybe<Scalars['ID']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  /** NPN number retrieved from NIPR, currently not used */
  agencyName?: InputMaybe<Scalars['String']>;
  agencyStateLicenseNumber?: InputMaybe<Scalars['String']>;
  agencyWebsite?: InputMaybe<Scalars['String']>;
  allowInspectionOfRecordsRelatedToWholesalerProducts?: InputMaybe<
    Scalars['Boolean']
  >;
  /** Authorized Signatory section */
  authoziedSignatory?: InputMaybe<AuthorizedSignatoryInput>;
  beforeYouGetStarted?: InputMaybe<Scalars['Boolean']>;
  carryFidelityBond?: InputMaybe<Scalars['Boolean']>;
  carrySurplusLinesLicense?: InputMaybe<Scalars['Boolean']>;
  /** Company Addresses section */
  companyAddresses?: InputMaybe<CompanyAddresses>;
  /** Company Contacts section */
  companyContacts?: InputMaybe<Array<InputMaybe<OrgContactInputs>>>;
  depositoryName?: InputMaybe<Scalars['String']>;
  /**
   * Upload documents section
   * E&O, Agency Licenses, and current W9 form
   */
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**
   * Upload documents section
   * E&O, Agency Licenses, and current W9 form
   */
  documents?: InputMaybe<Array<InputMaybe<OrgDocumentInput>>>;
  fein?: InputMaybe<Scalars['String']>;
  hasConvictedFelony?: InputMaybe<Scalars['Boolean']>;
  hasConvictedFelonyDescription?: InputMaybe<Scalars['String']>;
  hasDataSecurityIncidentsLast2Years?: InputMaybe<Scalars['Boolean']>;
  hasDisasterRecoveryPlan?: InputMaybe<Scalars['Boolean']>;
  hasDischargedBankruptcyLastFiveYears?: InputMaybe<Scalars['Boolean']>;
  hasDischargedBankruptcyLastFiveYearsDescription?: InputMaybe<
    Scalars['String']
  >;
  hasDishonestyMisdemeanor?: InputMaybe<Scalars['Boolean']>;
  hasDishonestyMisdemeanorDescription?: InputMaybe<Scalars['String']>;
  hasLawsuitsLastThreeYears?: InputMaybe<Scalars['Boolean']>;
  hasLawsuitsLastThreeYearsDescription?: InputMaybe<Scalars['String']>;
  hasLicenseRevokedLastThreeYears?: InputMaybe<Scalars['Boolean']>;
  hasLicenseRevokedLastThreeYearsDescription?: InputMaybe<Scalars['String']>;
  hasOfacScreenings?: InputMaybe<Scalars['Boolean']>;
  hasOffsiteDataBackup?: InputMaybe<Scalars['Boolean']>;
  hasWrittenAgreementWithEachRetailProducer?: InputMaybe<Scalars['Boolean']>;
  /** Historical Premium Volumes by years section */
  historicalPremiumVolumes?: InputMaybe<
    Array<InputMaybe<HistoricalPremiumVolumesInput>>
  >;
  namesOnTheAccount?: InputMaybe<Scalars['String']>;
  permitPaymentOfPremiumBeforeBinding?: InputMaybe<Scalars['Boolean']>;
  /** List of team members and licensed producers */
  producerList?: InputMaybe<Array<InputMaybe<Producer>>>;
  producerType?: InputMaybe<OrganizationProducerType>;
  prohibitAdvertisingWholesalerProductsWithoutApproval?: InputMaybe<
    Scalars['Boolean']
  >;
  prohibitBindingInsuranceWithoutApproval?: InputMaybe<Scalars['Boolean']>;
  requireClaimsForwardedWholesaler?: InputMaybe<Scalars['Boolean']>;
  requireConfidentialTreatmentOfProprietaryOrTradeSecretInformationProvidedByWholesaler?: InputMaybe<
    Scalars['Boolean']
  >;
  requireImplementationOfDataSecurityMeasures?: InputMaybe<Scalars['Boolean']>;
  requireNoticeToWholesalerOfDataSecurityIncident?: InputMaybe<
    Scalars['Boolean']
  >;
  requireRetentionOfRecordsRelatedToWholesalerProducts?: InputMaybe<
    Scalars['Boolean']
  >;
  routingNumber?: InputMaybe<Scalars['String']>;
  /** Signature Acknowledgement section */
  signatureAcknowledgement?: InputMaybe<SignatureAcknowledgementInput>;
  state?: InputMaybe<Scalars['String']>;
  tradeAssociations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tradeNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userAccountType?: InputMaybe<Scalars['String']>;
  yearsInBusiness?: InputMaybe<Scalars['Int']>;
};

export type SendAggregatedPayoutReportInputs = {
  records?: InputMaybe<Array<InputMaybe<AggregatedPayoutReportInputs>>>;
  reportTitle?: InputMaybe<Scalars['String']>;
};

export type SendBespokeDataAlertInputs = {
  collection: Scalars['String'];
  dataset: Scalars['String'];
};

export type SendDerivativesOverdueTransactionAlertInputs = {
  amount: Scalars['Float'];
  contractId: Scalars['String'];
  contractName: Scalars['String'];
  currency: Scalars['String'];
  dueDate: Scalars['String'];
  transactionId: Scalars['String'];
};

export type SendFinalDataCorrectionAlertInputs = {
  _id: Scalars['String'];
  correctedPayout: Scalars['Float'];
  currency: Scalars['String'];
  originalPayout: Scalars['Float'];
};

export type SendPrelimDataCorrectionAlertInputs = {
  _id: Scalars['String'];
  correctedPayout: Scalars['Float'];
  currency: Scalars['String'];
  originalPayout: Scalars['Float'];
};

export type SetLegacyEvaluationInputs = {
  arbolId: Scalars['String'];
  changedBy?: InputMaybe<Scalars['String']>;
  payout: Scalars['Float'];
  portfolio: Scalars['JSON'];
  riskContractId: Scalars['String'];
  riskTimestamp: Scalars['String'];
  riskVersion: Scalars['String'];
  type?: InputMaybe<LegacyEvaluationType>;
};

export type SignatureAcknowledgementInput = {
  date: Scalars['DateTime'];
  readAgreement: Scalars['Boolean'];
  signature: Scalars['String'];
  title: Scalars['String'];
};

export type SubmitGrpInsuranceApplicationInput = {
  agentSignature: AgentSignatureInput;
};

export type SubmitLpcInsuranceApplicationInput = {
  agentSignature: AgentSignatureInput;
};

export type Team = {
  __typename?: 'Team';
  confirmedAt?: Maybe<Scalars['DateTime']>;
  disabledAt?: Maybe<Scalars['DateTime']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedEmail?: Maybe<Scalars['String']>;
  invitedFirstName?: Maybe<Scalars['String']>;
  invitedLastName?: Maybe<Scalars['String']>;
  invitedOrgContactType?: Maybe<Role>;
  invitedStateLicenseNumber?: Maybe<Scalars['String']>;
  status?: Maybe<OrgTeamStatus>;
  user?: Maybe<User>;
};

export enum TeamType {
  Accounting = 'ACCOUNTING',
  SalesLead = 'SALES_LEAD',
  TradeOps = 'TRADE_OPS',
}

export enum TokenType {
  ClientClaimForm = 'CLIENT_CLAIM_FORM',
  ClientReviewContract = 'CLIENT_REVIEW_CONTRACT',
  Confirm = 'CONFIRM',
  ConfirmShortcode = 'CONFIRM_SHORTCODE',
  Login = 'LOGIN',
  Reset = 'RESET',
}

export type Transaction = {
  __typename?: 'Transaction';
  _id?: Maybe<Scalars['ID']>;
  contract?: Maybe<Contract>;
  createdAt?: Maybe<Scalars['DateTime']>;
  foreignCurrencySupport?: Maybe<ForeignCurrencySupport>;
  paid: Scalars['Boolean'];
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentMethod: PaymentMethod;
  sentToBank: Scalars['Boolean'];
  status: TransactionStatus;
  transactionAmount?: Maybe<UnitVectorFloat>;
  transactionContext: TransactionContext;
  transactionType: TransactionType;
  user?: Maybe<User>;
};

export enum TransactionContext {
  ClaimPayment = 'CLAIM_PAYMENT',
  Commission = 'COMMISSION',
  Payout = 'PAYOUT',
  Premium = 'PREMIUM',
  Refund = 'REFUND',
}

export enum TransactionStatus {
  Paid = 'PAID',
  Processing = 'PROCESSING',
}

export enum TransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

export type UnderwritingCheckHhInputs = {
  businessProperty?: InputMaybe<Scalars['Boolean']>;
  commercialProperty?: InputMaybe<Scalars['Boolean']>;
  condemnedProperty?: InputMaybe<Scalars['Boolean']>;
  divingBoard?: InputMaybe<Scalars['Boolean']>;
  farmOrRanch?: InputMaybe<Scalars['Boolean']>;
  fourOrMoreMortgages?: InputMaybe<Scalars['Boolean']>;
  fraternityOrSorority?: InputMaybe<Scalars['Boolean']>;
  homemadeOrRebuilt?: InputMaybe<Scalars['Boolean']>;
  misrepresentationArsonOrFraud?: InputMaybe<Scalars['Boolean']>;
  mobileOrPrefabricatedHome?: InputMaybe<Scalars['Boolean']>;
  nonHabitational?: InputMaybe<Scalars['Boolean']>;
  underConstruction?: InputMaybe<Scalars['Boolean']>;
};

export type UnitHistory = {
  __typename?: 'UnitHistory';
  history?: Maybe<Array<Maybe<YearVector>>>;
  unit?: Maybe<Scalars['String']>;
};

export type UnitVectorFloat = {
  __typename?: 'UnitVectorFloat';
  unit?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['Float']>;
};

export type UnitVectorFloatInputs = {
  unit: Scalars['String'];
  vector: Scalars['Float'];
};

export type UnitVectorInt = {
  __typename?: 'UnitVectorInt';
  unit?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['Int']>;
};

export type UnitVectorIntInputs = {
  unit: Scalars['String'];
  vector: Scalars['Int'];
};

export type UnitVectorRateFloat = {
  __typename?: 'UnitVectorRateFloat';
  rate?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  vector?: Maybe<Scalars['Float']>;
};

export type UnpauseApplicationInputs = {
  description?: InputMaybe<Scalars['String']>;
  isApproved: Scalars['Boolean'];
};

export type UpdateGrpInsuranceApplicationInputs = {
  businessDetails?: InputMaybe<GrpInsuranceApplicationBusinessDetails>;
  diligentSearchUpload?: InputMaybe<Scalars['String']>;
  entityInformation?: InputMaybe<GrpInsuranceApplicationEntityInformation>;
  uploadId?: InputMaybe<Scalars['String']>;
};

export type UpdateLegacyContractInputs = {
  additionalLcCosts?: InputMaybe<Scalars['Float']>;
  arbolNet?: InputMaybe<Scalars['Float']>;
  arbolNetUsd?: InputMaybe<Scalars['Float']>;
  arbol_commission?: InputMaybe<Scalars['Float']>;
  bankAdvisedRate?: InputMaybe<Scalars['Float']>;
  bespokeDataRequired?: InputMaybe<Scalars['Boolean']>;
  calculationDate?: InputMaybe<Scalars['String']>;
  capitalProvider?: InputMaybe<Scalars['String']>;
  changedBy?: InputMaybe<Scalars['String']>;
  clientCompany?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSignatureDate?: InputMaybe<Scalars['String']>;
  commission_calculated_from?: InputMaybe<Scalars['String']>;
  correctionPeriod?: InputMaybe<Scalars['Int']>;
  correctionPeriodEndDate?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dClimateFee?: InputMaybe<Scalars['Float']>;
  dClimateFeeUsd?: InputMaybe<Scalars['Float']>;
  direct?: InputMaybe<Scalars['String']>;
  driveId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  excessCollateral?: InputMaybe<Scalars['Float']>;
  holidays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  initialFxRate?: InputMaybe<Scalars['Float']>;
  lcBankName?: InputMaybe<Scalars['String']>;
  lcBankRate?: InputMaybe<Scalars['Float']>;
  lcBorrowingRate?: InputMaybe<Scalars['Float']>;
  lcCollateralRate?: InputMaybe<Scalars['Float']>;
  lcCollateralRateAdj?: InputMaybe<Scalars['Float']>;
  lcCollateralRateWeek?: InputMaybe<Scalars['String']>;
  lcCost?: InputMaybe<Scalars['Float']>;
  lcCostUsd?: InputMaybe<Scalars['Float']>;
  lcEndDate?: InputMaybe<Scalars['String']>;
  lcGrossRate?: InputMaybe<Scalars['Float']>;
  lcOtherCosts?: InputMaybe<Scalars['Float']>;
  lcOtherCostsUsd?: InputMaybe<Scalars['Float']>;
  lcStartDate?: InputMaybe<Scalars['String']>;
  lcTenorDays?: InputMaybe<Scalars['Int']>;
  lcType?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  limitUsd?: InputMaybe<Scalars['Float']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  paymentDate?: InputMaybe<Scalars['String']>;
  peril?: InputMaybe<Scalars['String']>;
  premium?: InputMaybe<Scalars['Float']>;
  premiumDueDate?: InputMaybe<Scalars['String']>;
  premiumUsd?: InputMaybe<Scalars['Float']>;
  program?: InputMaybe<Scalars['String']>;
  reinsurancePremium?: InputMaybe<Scalars['Float']>;
  reinsurancePremiumUsd?: InputMaybe<Scalars['Float']>;
  reinsurerSignatureDate?: InputMaybe<Scalars['String']>;
  salesAgentId?: InputMaybe<Scalars['String']>;
  sfOpportunityId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  tradeDate?: InputMaybe<Scalars['String']>;
};

export type UpdateLegacyTransactionInputs = {
  IBAN?: InputMaybe<Scalars['String']>;
  SWIFT?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  changedBy?: InputMaybe<Scalars['String']>;
  clientEmail?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  depositoryName?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fxRate?: InputMaybe<Scalars['Float']>;
  internationalPayment?: InputMaybe<Scalars['Boolean']>;
  ledger?: InputMaybe<Array<InputMaybe<LedgerEntry>>>;
  maskedAccountNumber?: InputMaybe<Scalars['String']>;
  maskedIBAN?: InputMaybe<Scalars['String']>;
  maskedRoutingNumber?: InputMaybe<Scalars['String']>;
  maskedSWIFT?: InputMaybe<Scalars['String']>;
  namesOnTheAccount?: InputMaybe<Scalars['String']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  paymentDate?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transactionAmount?: InputMaybe<Scalars['Float']>;
  transactionAmountUsd?: InputMaybe<Scalars['Float']>;
};

export type UpdateLpcInsuranceApplicationInputs = {
  businessDetails?: InputMaybe<GrpInsuranceApplicationBusinessDetails>;
  diligentSearchUpload?: InputMaybe<Scalars['String']>;
  entityInformation?: InputMaybe<GrpInsuranceApplicationEntityInformation>;
  taxes?: InputMaybe<
    Array<InputMaybe<UpdateLpcInsuranceApplicationTaxesInput>>
  >;
  uploadId?: InputMaybe<Scalars['String']>;
};

export type UpdateLpcInsuranceApplicationTaxesInput = {
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type UpdateOrganizationContactInputs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  type: Role;
};

export type UpdatePropertyInputs = {
  burglarAlarm?: InputMaybe<Scalars['Boolean']>;
  construction?: InputMaybe<ConstructionType>;
  fireAlarm?: InputMaybe<Scalars['Boolean']>;
  roofMaterial?: InputMaybe<RoofMaterialType>;
  roofYear?: InputMaybe<Scalars['Int']>;
  seasonalProperty?: InputMaybe<Scalars['Boolean']>;
  sprinklerSystem?: InputMaybe<Scalars['Boolean']>;
  yearBuilt?: InputMaybe<Scalars['Int']>;
};

export type UpdateProspectRequestInputs = {
  agreeToDiligenceSearch?: InputMaybe<Scalars['Boolean']>;
  property?: InputMaybe<Scalars['JSON']>;
  quotes?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  underWritingChecks?: InputMaybe<
    Array<InputMaybe<UnderWritingCheckOptionInput>>
  >;
};

export type UpdateUserInputs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  state?: InputMaybe<Scalars['String']>;
  stateLicenseNumber?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ID']>;
  activationCode?: Maybe<Scalars['String']>;
  clientIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['EmailAddress']>;
  finance?: Maybe<UserFinance>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  legal?: Maybe<UserLegal>;
  organization?: Maybe<Organization>;
  permissions?: Maybe<Array<Maybe<UserPermission>>>;
  profile?: Maybe<UserProfile>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFinance = {
  __typename?: 'UserFinance';
  isInvestor?: Maybe<Scalars['Boolean']>;
  paymentsInfo?: Maybe<Array<Maybe<UserFinancePaymentsInfo>>>;
};

export type UserFinancePaymentsInfo = {
  __typename?: 'UserFinancePaymentsInfo';
  _id?: Maybe<Scalars['ID']>;
  accountType?: Maybe<Scalars['String']>;
  depositoryName?: Maybe<Scalars['String']>;
  maskedAccountNumber?: Maybe<Scalars['String']>;
  maskedRoutingNumber?: Maybe<Scalars['String']>;
  namesOnTheAccount?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
};

export type UserLegal = {
  __typename?: 'UserLegal';
  nationalProducerNumber?: Maybe<Scalars['String']>;
  stateLicenseNumber?: Maybe<Scalars['String']>;
};

export type UserPaymentInfoInputs = {
  accountNumber: Scalars['String'];
  accountType: AccountType;
  depositoryName: Scalars['String'];
  namesOnTheAccount: Scalars['String'];
  routingNumber: Scalars['String'];
  signature?: InputMaybe<Scalars['String']>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  canApprove?: Maybe<Scalars['Boolean']>;
  canBind?: Maybe<Scalars['Boolean']>;
  canQuote?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<AppType>;
  team?: Maybe<TeamType>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type ValidateAgencyInputs = {
  fein: Scalars['String'];
  orgId: Scalars['String'];
};

export type ValidateAgencyResponse = {
  __typename?: 'ValidateAgencyResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ValidateAgentInputs = {
  email: Scalars['String'];
  licenseId: Scalars['String'];
  state: Scalars['String'];
};

export type ValidateAgentResponse = {
  __typename?: 'ValidateAgentResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type WeatherMetadata = {
  __typename?: 'WeatherMetadata';
  collection?: Maybe<Scalars['String']>;
  dataset?: Maybe<Scalars['String']>;
};

export type WeatherMetadataInput = {
  collection: Scalars['String'];
  dataset: Scalars['String'];
};

export type WeightedLocations = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  weight: Scalars['Float'];
};

export type YearRevenue = {
  __typename?: 'YearRevenue';
  revenue?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type YearRevenueInput = {
  revenue?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type YearVector = {
  __typename?: 'YearVector';
  vector?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['String']>;
};

export enum ConstructionType {
  Frame = 'Frame',
  Masonry = 'Masonry',
}

export type CreateParametricQuoteInputs = {
  collection: DatasetCollection;
  currency?: InputMaybe<CurrencyType>;
  dataset: Scalars['String'];
  end: Scalars['String'];
  indexDataId?: InputMaybe<Scalars['String']>;
  limit: Scalars['Float'];
  preferredStrike?: InputMaybe<Scalars['Float']>;
  programType: ProgramType;
  start: Scalars['String'];
  targetMatch?: InputMaybe<Scalars['Boolean']>;
  units: Scalars['String'];
  weightedLocations: Array<WeightedLocations>;
};

export enum RoofMaterialType {
  Other = 'Other',
  TileMetal = 'Tile_Metal',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type UnderWritingCheckOption = {
  __typename?: 'underWritingCheckOption';
  checked: Scalars['Boolean'];
  name: ProspectRequestUnderWritingCheckOptionName;
  value?: Maybe<Scalars['Boolean']>;
};

export type UnderWritingCheckOptionInput = {
  checked: Scalars['Boolean'];
  name: ProspectRequestUnderWritingCheckOptionName;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type AuthorizeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type AuthorizeMutation = {
  __typename?: 'Mutation';
  authorize?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename: 'User';
    _id?: string | null;
    confirmedAt?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    email?: any | null;
    role?: Role | null;
    profile?: {
      __typename: 'UserProfile';
      firstName?: string | null;
      lastName?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      companyName?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      zipcode?: string | null;
    } | null;
  } | null;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword?: boolean | null;
};

export type GetRainfallDataByLocationsQueryVariables = Exact<{
  input: Array<RainfallDataByLocationInput> | RainfallDataByLocationInput;
}>;

export type GetRainfallDataByLocationsQuery = {
  __typename?: 'Query';
  getCHIRPRainfallDataByLocations: Array<{
    __typename?: 'RainfallDataByLocation';
    city: string;
    country: string;
    date: string;
    location: Array<number>;
    rainfall: number;
    unit: string;
  }>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'AuthUserPayload';
    token: string;
    user: {
      __typename: 'User';
      _id?: string | null;
      confirmedAt?: any | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      email?: any | null;
      role?: Role | null;
      profile?: {
        __typename: 'UserProfile';
        firstName?: string | null;
        lastName?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        companyName?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        zipcode?: string | null;
      } | null;
    };
  } | null;
};

export type UserFragmentFragment = {
  __typename: 'User';
  _id?: string | null;
  confirmedAt?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  email?: any | null;
  role?: Role | null;
  profile?: {
    __typename: 'UserProfile';
    firstName?: string | null;
    lastName?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    companyName?: string | null;
    country?: string | null;
    phone?: string | null;
    state?: string | null;
    zipcode?: string | null;
  } | null;
};

export const UserFragment = gql`
  fragment userFragment on User {
    __typename
    _id
    confirmedAt
    createdAt
    updatedAt
    email
    role
    profile {
      __typename
      firstName
      lastName
      address1
      address2
      city
      companyName
      country
      firstName
      lastName
      phone
      state
      zipcode
    }
  }
`;
export const AuthorizeDocument = gql`
  mutation authorize($token: String!) {
    authorize(token: $token) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...userFragment
    }
  }
  ${UserFragment}
`;
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: EmailAddress!) {
    forgotPassword(email: $email, appType: SINGLIFE)
  }
`;
export const GetRainfallDataByLocationsDocument = gql`
  query getRainfallDataByLocations($input: [RainfallDataByLocationInput!]!) {
    getCHIRPRainfallDataByLocations(input: $input) {
      city
      country
      date
      location
      rainfall
      unit
    }
  }
`;
export const LoginDocument = gql`
  mutation login($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password, appType: SINGLIFE) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
export const ResetPasswordDocument = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragment}
`;
