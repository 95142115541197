import { Button, Grid } from '@mui/material';

interface IProps {
  disabled?: boolean;
  onAddRow: () => void;
}

export function FooterRow({ disabled, onAddRow }: IProps) {
  return (
    <Grid container item spacing={2}>
      <Grid item xs={6}>
        <Button
          disabled={disabled}
          sx={{ color: 'secondary.main', fontSize: '1.0625rem' }}
          size="small"
          onClick={onAddRow}
        >
          + Add new row
        </Button>
      </Grid>
    </Grid>
  );
}
