import { Paper } from '@mui/material';

import { HorizontalRuler } from '../HorizontalRuler';

import { ResultHeader } from './ResultHeader';
import { ResultTable } from './ResultTable';
import { ResultTableLoader } from './ResultTableLoader';
import { ISummary } from './interfaces';

interface IProps {
  data?: any;
  isLoading?: boolean;
  summary?: ISummary;
}

export function Results({ data, isLoading, summary }: IProps) {
  console.log('data', data);
  return (
    <Paper sx={{ padding: 4 }}>
      <ResultHeader
        data={data}
        cumulativeRainfall={summary?.cumulativeRainfall}
        isLoading={isLoading}
      />
      <HorizontalRuler />
      {isLoading && <ResultTableLoader />}
      {!isLoading && <ResultTable rows={data} />}
    </Paper>
  );
}
