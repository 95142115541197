import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface IProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label?: string;
}

export function ControllerTextField<T extends FieldValues>({
  control,
  label,
  name,
  ...inputProps
}: IProps<T> & TextFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          onChange={(event) =>
            field.onChange(
              inputProps.type === 'number'
                ? parseFloat(event.target.value)
                : event.target.value,
            )
          }
          error={Boolean(error)}
          helperText={error?.message}
          label={label}
          margin={inputProps.margin || 'none'}
          type={inputProps.type || 'text'}
          {...inputProps}
        />
      )}
    />
  );
}
